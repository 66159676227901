import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

const MAX_VALUE = 99;
const MIN_VALUE = 0;
const DEFAULT_VALUE = 1;

@Component({
  selector: 'app-modify-qty',
  templateUrl: './modify-qty.component.html',
  styleUrls: ['./modify-qty.component.scss'],
})
export class ModifyQtyComponent implements OnInit {
  @Input() initialQty: number = DEFAULT_VALUE;
  @Output() modifiedQty = new EventEmitter<number>();
  private qty: number;

  constructor() {}

  ngOnInit(): void {
    this.qty = this.initialQty;
  }

  increaseQty() {
    this.updateInput(Number(this.qty) + 1);
  }

  decreaseQty() {
    this.updateInput(Number(this.qty) - 1);
  }

  onInputFocus() {
    this.qty = null;
  }

  onInputBlur() {
    if (this.qty === null) {
      this.qty = DEFAULT_VALUE;
    }
  }

  updateInput(e: any | Number) {
    let updateValue = null;

    if (typeof e !== 'number') {
      const { value } = e.target;
      updateValue = value;
    } else {
      updateValue = e;
    }

    if (updateValue < MIN_VALUE) {
      this.qty = MIN_VALUE;
    } else if (updateValue > MAX_VALUE) {
      this.qty = MAX_VALUE;
    } else {
      this.qty = updateValue;
    }
    this.modifiedQty.emit(this.qty);
  }
}
