import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertController } from '@ionic/angular';
import { SavedCreditsService } from 'src/app/services/saved-credits/saved-credits-service.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { DvoRouterService } from 'src/app/services/dvo-router/dvo-router.service';
import { Paths, DVO_ENTITY_KEY, DVO_SAVED_CREDITS_KEY} from 'src/app/constants';
import { ToastService } from 'src/app/services/toast/toast.service';
import { EntityType } from 'src/app/app.interfaces';

@Component({
  selector: 'app-saved-credits',
  templateUrl: './saved-credits.component.html',
  styleUrls: ['./saved-credits.component.scss']
})
export class SavedCreditsComponent implements OnInit, OnDestroy {
  public credits: EntityType[] = [];
  private storageSub = new Subscription();

  constructor(
    private savedCredits: SavedCreditsService,
    private alertController: AlertController,
    private storage: LocalStorageService,
    private router: DvoRouterService,
    private toast: ToastService,
  ) {

  }

  ngOnInit() {
    this.credits = this.savedCredits.getAll();

    this.storageSub = this.storage.watchStorage().subscribe((storageKey: string) => {
      if(storageKey === DVO_SAVED_CREDITS_KEY){
        this.credits = this.savedCredits.getAll();
      }
    });
  }

  ngOnDestroy(){
    this.storageSub.unsubscribe()
  }

  removeSavedCredit(credit: EntityType){
    this.credits = this.savedCredits.deleteCredit(credit);
    this.toast.presentToast('DVO Credit Deleted')
  }

  openCredit(credit){
    //set the current dvo entity to localstroage
    this.storage.saveItem(DVO_ENTITY_KEY, {
      ...credit
    })

    //move into item scanning/lookup
    this.router.navigateByUrl(`/${Paths.reviewCredits}`)
  }

  async confirmRemoveItem(credit: EntityType, index: number){
    const alert = await this.alertController.create({
      header: 'Confirm',
      message: 'Are you sure you would like to delete this saved credit?',
      buttons: [
        {text: 'Cancel', handler:() => {}},
        {text: 'Delete Credit', handler: () => {
          this.removeSavedCredit(credit);
        }}
      ]
    })

    alert.present();
  }

  filterItems = (evt: UIEvent) => {
    const searchTerm = (evt.srcElement as HTMLInputElement).value.toLocaleLowerCase();

    if (!searchTerm) {
      this.credits = this.savedCredits.getAll();
      return;
    }

    this.credits = this.savedCredits.getAll().filter(credit => {
      const matchCreatedBy = credit.createdBy.toLocaleLowerCase().indexOf(searchTerm) > -1;
      const matchSubteamName = credit.subteam.name.toLocaleLowerCase().indexOf(searchTerm) > -1;

      return matchCreatedBy || matchSubteamName;
    });
  }
}
