import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { toBool } from 'src/app/services/app-settings/app-settings.service'

@Component({
  selector: 'app-mode-indicator',
  templateUrl: './mode-indicator.component.html',
  styleUrls: ['./mode-indicator.component.scss']
})
export class ModeIndicatorComponent implements OnInit {
  public isOnline: boolean = false;
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParamMap.subscribe((queryParamMap) => {
      this.isOnline = toBool(queryParamMap.get('ENABLE_LIVE_SERVICES'))
    })
  }

}
