import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { StoreService } from 'src/app/services/store/store.service';
import { Subteam } from 'src/app/app.interfaces';

@Injectable({
  providedIn: 'root'
})

export class StoreSubteamResolver implements Resolve<Subteam[]> {
  constructor(
    private storeService: StoreService
  ) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.storeService.getStoreSubteams();
  }
}
