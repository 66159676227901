import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-timeout-modal',
  templateUrl: './timeout-modal.component.html',
  styleUrls: ['./timeout-modal.component.scss']
})

export class TimeoutModalComponent {
  idleState: string;

  constructor(
    private modalController: ModalController,
    private auth: AuthService
    ) {}

  logout() {
    this.auth.logout();
    this.closeModal(false);
  }

  continueSession() {
    this.auth.getRefreshToken()
    .then(() => this.closeModal(true) );
  }

  async closeModal(dataToReturn: boolean) {
    await this.modalController.dismiss(dataToReturn);
  }
}
