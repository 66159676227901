import * as moment from 'moment';

/**
 *
 * @param startWeek start week (-1: last week, 0: this week, 1: next week)
 * @param days day range since the starting point of `startWeek`
 * @example
 * ```
 *  const lastWeek = getDayRangeFromWeek(0, 7)
 *  const lastTwoweeks = getDayRangeFromWeek(2, 14) (default)
 * ```
 */
export function getDayRangeFromWeek(startWeek = 2, days = 14) {
  let weekStart = moment().add(1,'days').subtract(startWeek, 'weeks');

  return [...Array(days)].map((_, i) =>
    weekStart.clone().add(((days - 1) - i), 'day').format('YYYY-MM-DD')
  );
}

export function formatItemDate(date: string) {
  return moment(date).format('YYYY-MM-DD');
} 