import { version } from '../../package.json';
import { ReasonCode } from 'src/app/app.interfaces';

export const CREDIT = 'credit';
export const ORDER = 'order';

export const REDACTED = 'REDACTED FROM LOGS';

export const Paths = {
  root: '',
  login: 'login',
  home: 'home',
  orderSubTeam: 'order-subteam',
  itemEntry: 'item-entry',
  createCredit: 'create-credit',
  createCreditQuantity: 'credit-quantity',
  orderItemDetail: 'order-item-detail',
  orderItemReview: 'order-item-review',
  orderSubmission: 'order-submission',
  savedOrders: 'saved-orders',
  savedCredits: 'saved-credits',
  reviewCredits: 'review-credits',
  settings: 'settings',
};

export enum AppOptions {
  ENABLE_LIVE_SERVICES = 'ENABLE_LIVE_SERVICES',
  DISABLE_APP_SETTINGS = 'DISABLE_APP_SETTINGS',
}

export enum ItemStatus {
  ACTIVE = 'A',
  DISCONTINUED = 'S',
  DELETED = 'D',
  NOT_AVAILABLE = 'N',
}

export const REASON_CODES: ReasonCode[] = [
  'Shortage',
  'Quality',
  'Mis-ship',
  'Damaged',
  'Cost',
  'Customer Return',
  'Out of Stock',
  'Short Dated',
  'Quarterly Credit',
  'Magazine Covers',
  'Recalled',
  'Mislabeled',
  'Demo Credit',
  'Vendor',
  'Guaranteed Sale',
  'Duplicate',
  'Spoiled',
  'Less than Invoiced',
];

/**
 * Official SemVer regex: https://semver.org/#is-there-a-suggested-regular-expression-regex-to-check-a-semver-string
 * Group 1: Major
 * Group 2: Minor
 * Group 3: Patch
 * Group 4: Pre-release (this is the equivalent of our Build Number)
 * Group 5: Build metadata (not currently used)
 */
export const REGEX_SEMVER = /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/;

const getVersionGroup = (groupIdx: number) => version.match(REGEX_SEMVER)[groupIdx];

export const NPM_VERSION = version;
export const MAJOR_VERSION = getVersionGroup(1);
export const MINOR_VERSION = getVersionGroup(2);
export const PATCH_VERSION = getVersionGroup(3);
export const CI_BUILD_NUMBER = getVersionGroup(4);
export const BUILD_ID = CI_BUILD_NUMBER ? `${CI_BUILD_NUMBER}` : `${MAJOR_VERSION}${MINOR_VERSION}${PATCH_VERSION}`;

// Since we sneak our CI/BUILD Run Number in as the pre-release (bc npm strips out build meta)
// we need to put it back in the correct semver form
// example: 1.2.4-205 ---> 1.2.4+205
export const VERSION = `v${MAJOR_VERSION}.${MINOR_VERSION}.${PATCH_VERSION}`;
export const SEM_VER = CI_BUILD_NUMBER ? VERSION.concat(`+${CI_BUILD_NUMBER}`) : VERSION;

export const VersionKey = (key: string) => `${key}-v${MAJOR_VERSION}`;

export const BuildKey = (key: string) => `${key}-${BUILD_ID}`;

export enum DVOKeyName {
  SESSION = 'dvo-session',
  CONIG = 'dvo-app-config',
  ENTITY = 'dvo-entity',
  SAVED_ORDERS = 'dvo-saved-orders',
  SAVED_CREDITS = 'dvo-saved-credits',
}

export const DVO_SESSION_KEY = BuildKey(DVOKeyName.SESSION);
export const DVO_CONFIG_KEY = BuildKey(DVOKeyName.CONIG);
export const DVO_ENTITY_KEY = BuildKey(DVOKeyName.ENTITY);
export const DVO_SAVED_ORDERS_KEY = BuildKey(DVOKeyName.SAVED_ORDERS);
export const DVO_SAVED_CREDITS_KEY = BuildKey(DVOKeyName.SAVED_CREDITS);
