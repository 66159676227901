import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { DvoRouterService } from 'src/app/services/dvo-router/dvo-router.service';
import { Paths, CREDIT, ORDER } from 'src/app/constants';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(private router: DvoRouterService, public modalController: ModalController, private storage: LocalStorageService) {}

  ngOnInit() {}

  createOrders = (): void => {
    this.router.navigateByUrl(`${Paths.orderSubTeam}/${ORDER}`);
  };

  createCredits = (): void => {
    this.router.navigateByUrl(`${Paths.orderSubTeam}/${CREDIT}`);
  };
}
