import { DVO_SESSION_KEY } from'src/app/constants';
import { getAPIHost, createAPIResourceURL } from  'src/app/utils/createResourceURL';

export const jwtOptionsFactory = (localStorageService) =>{
  return {
    whitelistedDomains: [getAPIHost()],
    tokenGetter: () => {
      const appData = localStorageService.getItem(DVO_SESSION_KEY)
      const token = appData.accessToken ? appData.accessToken : null;
      return token
    },
    blacklistedRoutes: [
      createAPIResourceURL('login'),
    ]
  }
}
