import { Component, OnInit, NgZone } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';

import { DvoRouterService } from 'src/app/services/dvo-router/dvo-router.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { ItemService } from 'src/app/services/item/item.service';
import { DvoEntityService } from 'src/app/services/dvo-entity/dvo-entity.service';
import { Paths, ORDER } from 'src/app/constants';
import { BarcodeScanner, BarcodeSymbology } from '@wfmes/mobile';
import { IBarcodeScannedEvent } from '@wfmes/mobile/dist/BarcodeScanner';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-order-item-detail',
  templateUrl: './order-item-detail.component.html',
  styleUrls: ['./order-item-detail.component.scss'],
})
export class OrderItemDetailComponent implements OnInit {
  public itemQuantity: number;
  public shouldNavBackToReview: boolean;
  public shouldModifyItem: boolean;
  public isViewingAvgTbl = true;
  public rawItemDetails;
  public displayItemDetails = [];
  private shouldActivateNavGuard: boolean;

  constructor(
    private router: DvoRouterService,
    private activatedRoute: ActivatedRoute,
    private alertController: AlertController,
    public toast: ToastService,
    public itemService: ItemService,
    private dvoOrder: DvoEntityService,
    private zone: NgZone,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.loadDetails();

    const routeState = this.activatedRoute.paramMap.pipe(map(() => window.history.state));

    routeState.subscribe(({ shouldNavBackToReview = false }) => {
      this.shouldNavBackToReview = shouldNavBackToReview;
      this.shouldModifyItem = shouldNavBackToReview || this.dvoOrder.isDuplicatedItem(this.rawItemDetails.upc);

      // By piggybacking off this subscription we can reset the nav guard
      // upon subsequent route changes
      this.shouldActivateNavGuard = true;
      this.registerBarcodeHandler();
    });

    this.registerBarcodeHandler();

    this.authService.onUserAuthChange().subscribe((isLoggedIn) => {
      if (!isLoggedIn) {
        this.shouldActivateNavGuard = false;
      }
    });
  }

  loadDetails() {
    this.rawItemDetails = this.activatedRoute.snapshot.data.itemData;
    this.displayItemDetails = this.rawItemDetails.history;
    this.itemQuantity = this.rawItemDetails.quantity ? this.rawItemDetails.quantity : 1;
  }

  async searchItem(scanData: IBarcodeScannedEvent) {
    const { Data, Symbology } = scanData;
    const upc = Data.toString();
    const symbology = Symbology as BarcodeSymbology;
    const transformedUPC = this.itemService.getTransformedUPC(upc, symbology);

    this.shouldActivateNavGuard = false;
    /**
     * band-aid solution to reload the page if user scans
     * a new item within a detail page, while maintaining
     * behavior if item lookup fails or is discontinued
     */
    try {
      this.router.navigateByUrl(`${Paths.itemEntry}/${ORDER}`, { state: { shouldActivateNavGuard: false } }).then(() => {
        // prevents the gaurd displaing back to back
        // if cancel is pressed
        this.router.navigateByUrl(`${Paths.orderItemDetail}/${transformedUPC}`);
      });
    } catch (ex) {
      console.error(ex);
    }
  }

  /**
   * @description Method used by CanDeactivateGuard to block navigation
   * changes in the event the user has not saved the item.
   */
  canDeactivate(): Promise<boolean> | boolean {
    if (this.shouldActivateNavGuard) {
      return this.confirmNavigateAway();
    }

    return true;
  }

  toggleAvgTbl() {
    this.isViewingAvgTbl = true;
  }

  togglePOSTbl() {
    this.isViewingAvgTbl = false;
  }

  async showDetails() {
    const alert = await this.alertController.create({
      header: 'Item Details',
      message: `
        * Region Rank: ${this.rawItemDetails.regionRank || 'N/A'} <br/><br/>
        * Store Item Rank: ${this.rawItemDetails.storeRank || 'N/A'} <br/><br/>
        * Store Subteam Rank: ${this.rawItemDetails.subTeamRank || 'N/A'} <br/><br/>
        * Retail Cost: $${this.rawItemDetails.cost || 'N/A'}
      `,
      buttons: ['OK'],
    });

    await alert.present();
  }

  onReviewOrders() {
    this.clearBarcodeHandler();
  }

  registerBarcodeHandler() {
    BarcodeScanner.registerHandler((scan: IBarcodeScannedEvent) => {
      try {
        this.zone.run(() => this.searchItem(scan));
      } catch (ex) {
        alert(ex.message);
      }
    });
  }

  clearBarcodeHandler() {
    BarcodeScanner.registerHandler((scan: IBarcodeScannedEvent) => { });
  }

  async confirmNavigateAway(): Promise<boolean> {
    return new Promise(async (resolve) => {
      const alert = await this.alertController.create({
        header: 'Unsaved Item',
        message: `Leaving the active order window without first Saving or Sending will result in the loss of your order`,
        buttons: [
          {
            text: 'OK',
            handler: () => {
              alert.dismiss();
              this.clearBarcodeHandler();
              resolve(true);
            },
          },
          {
            text: 'Cancel',
            handler: () => {
              alert.dismiss();
              resolve(false);
            },
          },
        ],
      });

      await alert.present();
    });
  }

  async modifySavedQty() {
    const alert = await this.alertController.create({
      header: 'Item Details',
      message: `Are you sure you want to modify the quantity of item: ${this.rawItemDetails.description}?`,
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.updateItem();
          },
        },
        {
          text: 'Cancel',
          handler: () => {
            alert.dismiss();
          },
        },
      ],
    });

    await alert.present();
  }

  onQtyModified(newQty: number): void {
    this.itemQuantity = newQty;
  }

  returnToScan() {
    this.router.navigateByUrl(`${Paths.itemEntry}/${ORDER}`, { state: { shouldActivateNavGuard: true } });
  }

  returnToOrder() {
    this.router.navigateByUrl(Paths.orderItemReview);
  }

  addToOrder() {
    // The user has acknowledged the changes, so we do not need to trigger
    // the navigation guard.
    this.shouldActivateNavGuard = false;

    const item = {
      ...this.rawItemDetails,
      quantity: this.itemQuantity,
    };

    this.dvoOrder.updateDvoItem(item).then(
      (message) => {
        this.toast.presentToast(message);
        this.returnToScan();
      },
      (err) => {
        const msg = err.message ? err.message : err;
        this.displayError(msg);
      }
    );
  }

  updateItem() {
    // The user has acknowledged the changes, so we do not need to trigger
    // the navigation guard.
    this.shouldActivateNavGuard = false;

    const item = {
      ...this.rawItemDetails,
      quantity: this.itemQuantity,
    };

    this.dvoOrder.updateDvoItem(item).then(
      (message) => {
        this.toast.presentToast(message);
        if (this.shouldNavBackToReview) {
          this.returnToOrder();
        } else {
          this.returnToScan();
        }
      },
      (err) => {
        const msg = err.message ? err.message : err;
        this.displayError(msg);
      }
    );
  }

  async displayError(message: string) {
    const alert = await this.alertController.create({
      header: 'Error',
      message,
      buttons: ['OK'],
    });

    alert.present();
  }
}
