import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { AlertOptions } from '@ionic/core';

interface DisplayWarning {
  header: string;
  message: string;
  options?: AlertOptions;
}

@Injectable({
  providedIn: 'root'
})
export class WarningService {

  constructor(
    private alertController: AlertController,
  ) { }

  async displayWarning({
    header,
    message,
    options = {},
  }: DisplayWarning) {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: [
        {
          text: 'Ok',
        }
      ],
      ...options,
    })

    await alert.present();
  }
}
