import { Injectable } from '@angular/core';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { DVO_ENTITY_KEY } from 'src/app/constants';
import { SavedOrdersService } from 'src/app/services/saved-orders/saved-orders.service';
import { SavedCreditsService } from 'src/app/services/saved-credits/saved-credits-service.service';
import { Subteam, EntityType, Item } from 'src/app/app.interfaces';

@Injectable({
  providedIn: 'root',
})
export class DvoEntityService {
  constructor(private storage: LocalStorageService, private savedOrders: SavedOrdersService, private savedCredits: SavedCreditsService) {}

  /**
   * Gets the current open DVO Entity Item
   * being either an ORDER or a CREDIT
   * object
   */
  current(): EntityType {
    return this.storage.getItem(DVO_ENTITY_KEY) || {};
  }

  update(updatedEntity: EntityType) {
    this.storage.update(DVO_ENTITY_KEY, updatedEntity);
  }

  getItemByUpc(upc: string) {
    return this.items.find((item) => item.upc === upc);
  }

  isDuplicatedItem(upc) {
    const duplicatedItem = this.getItemByUpc(upc);
    if (duplicatedItem) {
      return true;
    }
    return false;
  }

  /**
   * @description Adds a dvoItem to the `dvo-entity` localstorage object
   * If a upc for a given item is not found, the item is added
   * If the item exist in storage, it is updated
   * if the item is updated with 0 value, it is removed
   * Items cannot be added with a 0
   * @param dvoItem
   */
  async updateDvoItem(dvoItem: Item, enforceNonZeroQty: boolean = true): Promise<string> {
    //In the event an item is added with a QTY of 0
    //it should be removed from the items array
    const { quantity, upc = '' } = dvoItem;

    if (upc === '') {
      return Promise.reject('Item is missing a UPC value');
    }

    if (enforceNonZeroQty && quantity <= 0) {
      if (this.isDuplicatedItem(upc)) {
        //If a value is edited with 0 value, it should be removed
        //from the current items
        await this.deleteItemByUPC(dvoItem.upc);
        return Promise.resolve(`${dvoItem.description} Removed`);
      }
      return Promise.reject('Invalid Quantity Value');
    }

    return new Promise((resolve, reject) => {
      try {
        const currEntity = this.current();
        let entityState = 'Added';

        if (this.isDuplicatedItem(dvoItem.upc)) {
          this.updateItemByUPC(dvoItem.upc, dvoItem).catch(() => reject('Error Updating Item'));
          entityState = 'Updated';
        } else {
          this.update({
            ...currEntity,
            items: [...currEntity.items, dvoItem],
          });
        }

        resolve(`${dvoItem.description} ${entityState}`);
      } catch (ex) {
        reject(ex);
      }
    });
  }

  updateItemByUPC(upc: string, value: Item) {
    return new Promise((resolve, reject) => {
      try {
        let currentEntity = this.current();
        const { items: entityItems } = currentEntity;
        const prevItemDetails = currentEntity.items.find((item: Item) => item.upc === upc);
        const updatedItem = {
          ...prevItemDetails,
          ...value,
        };

        currentEntity.items = entityItems.map((dvoItem: Item) => {
          if (dvoItem.upc === upc) {
            return updatedItem;
          }
          return dvoItem;
        });

        this.update(currentEntity);

        resolve();
      } catch (ex) {
        reject(ex);
      }
    });
  }

  deleteItemByUPC(upc: string): Promise<Item[]> {
    return new Promise((resolve, reject) => {
      try {
        let openEntity = this.current();
        openEntity.items = openEntity.items.filter((dvoItem) => dvoItem.upc !== upc);
        this.update(openEntity);

        resolve(openEntity.items);
      } catch (ex) {
        reject(ex);
      }
    });
  }

  get items() {
    const { items = [] } = this.current();
    return items;
  }

  /**
   * @param {boolean} deleteEntityAfterSave Should the current DVO
   * Entity be deleted after saving?
   */
  saveCurrentEntity({ deleteEntityAfterSave = false }: { deleteEntityAfterSave?: boolean } = {}) {
    const currentEntity = this.current();
    if (currentEntity) {
      const entityHasItems = currentEntity.items && currentEntity.items.length > 0;

      if (entityHasItems && currentEntity.entityType === 'order') {
        this.savedOrders.save(currentEntity);
      } else if (entityHasItems && currentEntity.entityType === 'credit') {
        this.savedCredits.save(currentEntity);
      }

      if (deleteEntityAfterSave) {
        // We delete the current entity after saving it so we don't
        // see it during the login procedure and try to save it again
        this.delete();
      }
    }
  }

  getTotalItemsScanned() {
    return this.items.length;
  }

  getCurrentSubTeam(): Subteam {
    return this.current().subteam;
  }

  getReason(): string {
    return this.current().reason;
  }

  getTotalCasesScanned() {
    return this.items.reduce((acc, val) => Number(acc) + Number(val.quantity), 0);
  }

  delete() {
    this.storage.update(DVO_ENTITY_KEY, {
      createdBy: '',
      createdOn: '',
      entityType: null,
      id: null,
      items: [],
      subteam: {},
      reason: '',
    });
  }
}
