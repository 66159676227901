import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { WfmUIModule } from '@wfmes/ui-angular';
import { IonicModule } from '@ionic/angular';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { OrderModule } from 'ngx-order-pipe';

import { AppInitService, init_app } from 'src/app/app-initialize';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { jwtOptionsFactory } from 'src/app/factories/jwtOptionsFactory';
import { AppRoutingModule } from 'src/app/routing/app-routing.module';
import { AppComponent } from 'src/app/app.component';
import { LoginComponent } from 'src/app/pages/login/login.component';
import { HomeComponent } from 'src/app/pages/home/home.component';
import { LoadCacheComponent } from 'src/app/pages/home/load-cache/load-cache.component';
import { ToolbarComponent } from 'src/app/components/toolbar/toolbar.component';
import { OrderSubteamComponent } from 'src/app/pages/order-subteam/order-subteam.component';
import { TimeoutMessageComponent } from 'src/app/components/timeout-message/timeout-message.component';
import { TimeoutModalComponent } from 'src/app/modals/timeout-modal.component';
import { TimeoutComponent } from 'src/app/components/timeout/timeout.component';
import { ItemEntryComponent } from 'src/app/pages/item-entry/Item-entry.component';
import { OrderItemDetailComponent } from 'src/app/pages/order-item-detail/order-item-detail.component';
import { OrderItemReviewComponent } from 'src/app/pages/order-item-review/order-item-review.component';
import { ToastService } from 'src/app/services/toast/toast.service';
import { CreateCreditComponent } from 'src/app/pages/create-credit/create-credit.component';
import { CreditQuantityComponent } from 'src/app/pages/create-credit/credit-quantity/credit-quantity.component';
import { SavedOrdersComponent } from 'src/app/pages/saved-orders/saved-orders.component';
import { SavedCreditsComponent } from 'src/app/pages/saved-credits/saved-credits.component';
import { StoreLocationComponent } from 'src/app/components/store-location/store-location.component';
import { ReviewCreditsComponent } from 'src/app/pages/review-credits/review-credits.component';
import { OrderReviewMenuComponent } from 'src/app/pages/order-item-review/order-review-menu/order-review-menu.component';
import { CreditReviewMenuComponent } from 'src/app/pages/review-credits/credit-review-menu/credit-review-menu.component';
import { ModeIndicatorComponent } from 'src/app/components/mode-indicator/mode-indicator.component';
import { EmptyStateComponent } from 'src/app/components/empty-state/empty-state.component';
import { SettingsComponent } from 'src/app/pages/settings/settings.component';
import { CanDeactivateGuard } from 'src/app/routing/can-deactivate-guard.service';
import { ModifyQtyComponent } from './components/modify-qty/modify-qty.component';
import { HelpComponent } from 'src/app/components/help/help.component';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
@NgModule({
  entryComponents: [LoadCacheComponent, TimeoutModalComponent, OrderReviewMenuComponent, CreditReviewMenuComponent, HelpComponent],
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    LoadCacheComponent,
    ToolbarComponent,
    OrderSubteamComponent,
    TimeoutMessageComponent,
    TimeoutModalComponent,
    TimeoutComponent,
    ItemEntryComponent,
    OrderItemDetailComponent,
    OrderItemReviewComponent,
    OrderReviewMenuComponent,
    CreateCreditComponent,
    CreditQuantityComponent,
    SavedOrdersComponent,
    SavedCreditsComponent,
    StoreLocationComponent,
    ReviewCreditsComponent,
    CreditReviewMenuComponent,
    ModeIndicatorComponent,
    EmptyStateComponent,
    SettingsComponent,
    HelpComponent,
    ModifyQtyComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    OrderModule,
    AppRoutingModule,
    WfmUIModule.forRoot(),
    IonicModule.forRoot({ mode: 'md' }),
    NgIdleKeepaliveModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [LocalStorageService],
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  schemas: [],
  providers: [
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [AppInitService],
      multi: true,
    },
    ToastService,
    CanDeactivateGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
