import { Component, OnInit } from '@angular/core';
import { PopoverController, AlertController } from '@ionic/angular';

import { SavedCreditsService } from 'src/app/services/saved-credits/saved-credits-service.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { WarningService } from 'src/app/services/warning/warning.service';
import { DvoEntityService } from 'src/app/services/dvo-entity/dvo-entity.service';

@Component({
  selector: 'app-credit-review-menu',
  templateUrl: './credit-review-menu.component.html',
  styleUrls: ['./credit-review-menu.component.scss'],
})
export class CreditReviewMenuComponent implements OnInit {
  constructor(
    private popOver: PopoverController,
    private toast: ToastService,
    private alertController: AlertController,
    private savedCredits: SavedCreditsService,
    private warning: WarningService,
    private dvoCredit: DvoEntityService
  ) {}

  ngOnInit() {}

  save() {
    const credit = this.dvoCredit.current();

    if (credit.items.length > 0) {
      this.savedCredits.save(credit);
      this.dvoCredit.delete();
      this.toast.presentToast('DVO Credit Saved');
      this.popOver.dismiss('saved');
    } else {
      this.popOver.dismiss();
      this.warning.displayWarning({
        header: 'Items Missing',
        message: 'Empty credits cannot be saved.',
      });
    }
  }

  async confirmDeleteOrder() {
    const alert = await this.alertController.create({
      header: 'Confirm Delete',
      message: 'Are you sure you would like to delete this credit?',
      buttons: [
        {
          text: 'Cancel',
          handler: () => {
            this.popOver.dismiss('canceled');
          },
        },
        {
          text: 'Delete',
          handler: () => {
            this.deleteOrder();
          },
        },
      ],
    });

    await alert.present();
  }

  deleteOrder() {
    const { id } = this.dvoCredit.current();
    this.dvoCredit.delete();

    if (id) {
      this.savedCredits.deleteCreditById(id);
    }

    this.popOver.dismiss('deleted');
    this.toast.presentToast('DVO Credit Deleted');
  }

  changeCreditReason() {
    this.popOver.dismiss('change reason');
  }
}
