import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertController } from '@ionic/angular';
import { SavedOrdersService } from 'src/app/services/saved-orders/saved-orders.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { DvoRouterService } from 'src/app/services/dvo-router/dvo-router.service';
import { Paths, DVO_ENTITY_KEY, DVO_SAVED_ORDERS_KEY } from 'src/app/constants';
import { ToastService } from 'src/app/services/toast/toast.service';
import { EntityType } from 'src/app/app.interfaces';

@Component({
  selector: 'app-saved-orders',
  templateUrl: './saved-orders.component.html',
  styleUrls: ['./saved-orders.component.scss']
})
export class SavedOrdersComponent implements OnInit {
  public orders: EntityType[] = [];
  private storageSub = new Subscription();

  constructor(
    private savedOrders: SavedOrdersService,
    private alertController: AlertController,
    private storage: LocalStorageService,
    private router: DvoRouterService,
    private toast: ToastService,
  ) {}

  ngOnInit() {
    this.orders = this.savedOrders.getAll();

    this.storageSub = this.storage.watchStorage().subscribe((storageKey: string) => {
      if(storageKey === DVO_SAVED_ORDERS_KEY){
        this.orders = this.savedOrders.getAll();
      }
    });
  }

  ngOnDestroy(){
    this.storageSub.unsubscribe()
  }

  deleteOrder(order: EntityType){
    this.orders = this.savedOrders.deleteOrder(order);
    this.toast.presentToast('DVO Order Deleted')
  }

  openOrder(order){
    //set the current dvo entity to localstroage
    this.storage.saveItem(DVO_ENTITY_KEY, {
      ...order
    })
    this.router.navigateByUrl(`/${Paths.orderItemReview}`)
  }

  async confirmRemoveItem(order: EntityType, index: number){
    const alert = await this.alertController.create({
      header: 'Confirm',
      message: 'Are you sure you would like to delete this saved order?',
      buttons: [
        {text: 'Cancel', handler:() => {}},
        {text: 'Delete Order', handler: () => {
          this.deleteOrder(order);
        }}
      ]
    })

    alert.present();
  }

  filterItems = (evt: UIEvent) => {
    const searchTerm = (evt.srcElement as HTMLInputElement).value.toLocaleLowerCase();

    if (!searchTerm) {
      this.orders = this.savedOrders.getAll();
      return;
    }

    this.orders = this.savedOrders.getAll().filter(order => {
      const matchCreatedBy = order.createdBy.toLocaleLowerCase().indexOf(searchTerm) > -1;
      const matchSubteamName = order.subteam.name.toLocaleLowerCase().indexOf(searchTerm) > -1;

      return matchCreatedBy || matchSubteamName;
    });
  }
}
