import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

import { Paths, CREDIT } from 'src/app/constants';
import { ToastService } from 'src/app/services/toast/toast.service';
import { DvoRouterService } from 'src/app/services/dvo-router/dvo-router.service';
import { DvoEntityService } from 'src/app/services/dvo-entity/dvo-entity.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Item } from 'src/app/app.interfaces';

@Component({
  selector: 'app-credit-quantity',
  templateUrl: './credit-quantity.component.html',
  styleUrls: ['./credit-quantity.component.scss'],
})
export class CreditQuantityComponent implements OnInit {
  public item: Item;
  public itemQuantity: number;
  public shouldNavBackToReview: boolean;
  public shouldModifyItem: boolean;
  public selectedReason: string;
  private shouldActivateNavGuard: boolean;

  constructor(
    public router: DvoRouterService,
    private toast: ToastService,
    private dvoCredit: DvoEntityService,
    private alertController: AlertController,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.item = this.activatedRoute.snapshot.data.itemData;
    this.selectedReason = this.dvoCredit.getReason();

    const routeState = this.activatedRoute.paramMap.pipe(map(() => window.history.state));

    // the DVO credit app flow has two step/views
    // in selecting a reason then adding quantity.
    //
    // we dont want to add our item to storage yet
    // because we want to maintain the
    // check against an initial 0 quantity value
    routeState.subscribe(({ item = {}, shouldNavBackToReview = false }) => {
      this.shouldNavBackToReview = shouldNavBackToReview;
      //this.item = item;
      this.itemQuantity = this.item.quantity || 1;

      // By piggybacking off this subscription we can reset the nav guard
      // in the event the user goes back to change the item reason.
      this.shouldActivateNavGuard = true;
      this.shouldModifyItem = shouldNavBackToReview || this.dvoCredit.isDuplicatedItem(this.item.upc);
    });

    this.authService.onUserAuthChange().subscribe((isLoggedIn) => {
      if (!isLoggedIn) {
        this.shouldActivateNavGuard = false;
      }
    });
  }

  /**
   * @description Method used by CanDeactivateGuard to block navigation
   * changes in the event the user has not saved the item.
   */
  canDeactivate(): Promise<boolean> | boolean {
    if (this.shouldActivateNavGuard) {
      return this.confirmNavigateAway();
    }

    return true;
  }

  /**
   * @description Alert shown when user attempts to navigate away before
   * changes have been saved.
   */
  async confirmNavigateAway(): Promise<boolean> {
    return new Promise(async (resolve) => {
      const alert = await this.alertController.create({
        header: 'Unsaved Item',
        message: `Leaving the active order window without first Saving or Sending will result in the loss of your order`,
        buttons: [
          {
            text: 'OK',
            handler: () => {
              alert.dismiss();
              resolve(true);
            },
          },
          {
            text: 'Cancel',
            handler: () => {
              alert.dismiss();
              resolve(false);
            },
          },
        ],
      });

      await alert.present();
    });
  }

  changeReason() {
    // Do not show the guard when user goes back to change the reason.
    this.shouldActivateNavGuard = false;

    this.router.navigateByUrl(`/${Paths.createCredit}`, {
      state: {
        shouldNavBackToReview: this.shouldNavBackToReview,
      },
    });
  }

  continue() {
    // The user has acknowledged the changes, so we do not need to trigger
    // the navigation guard.
    this.shouldActivateNavGuard = false;

    this.item.quantity = this.itemQuantity;

    this.dvoCredit.updateDvoItem(this.item).then(
      (message) => {
        this.toast.presentToast(message);
        this.router.navigateByUrl(`/${Paths.itemEntry}/${CREDIT}`);
      },
      (err) => {
        this.displayError(err);
      }
    );
  }

  updateItem() {
    // The user has acknowledged the changes, so we do not need to trigger
    // the navigation guard.
    this.shouldActivateNavGuard = false;

    // If user updated the "reason", this was already applied in
    // "create-credit.component". We only need to update the quantity here.
    this.item.quantity = this.itemQuantity;

    this.dvoCredit.updateDvoItem(this.item).then(
      (message) => {
        this.toast.presentToast(message);
        if (this.shouldNavBackToReview) {
          this.router.navigateByUrl(Paths.reviewCredits);
        } else {
          this.router.navigateByUrl(`/${Paths.itemEntry}/${CREDIT}`);
        }
      },
      (err) => {
        this.displayError(err);
      }
    );
  }

  async modifySavedQty() {
    const alert = await this.alertController.create({
      header: 'Item Details',
      message: `Are you sure you want to modify the item: ${this.item.description}?`,
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.updateItem();
          },
        },
        {
          text: 'Cancel',
          handler: () => {
            alert.dismiss();
          },
        },
      ],
    });

    await alert.present();
  }

  onQtyModified(newQty: number): void {
    this.itemQuantity = newQty;
  }

  async displayError(message: string) {
    const alert = await this.alertController.create({
      header: 'Error',
      message,
      buttons: ['OK'],
    });

    alert.present();
  }
}
