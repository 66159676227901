import { DVO_CONFIG_KEY } from 'src/app/constants';
declare let window: Window;

export class DynamicEnvironment {
  private config: DvoEnvironmentConfig;

  constructor() {
    this.config = JSON.parse(localStorage.getItem(DVO_CONFIG_KEY));
  }

  public get baseUrl() {
    const appConfig = this.config || window.config;
    return appConfig.baseUrl;
  }

  public get lrAppId() {
    const appConfig = this.config || window.config;
    return appConfig.lrAppId;
  }
}
