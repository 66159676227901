import { PO } from 'src/app/app.interfaces';

const getLastOrderItemIdQuery = (): string => 'SELECT Max(Version) FROM PoHistory';

const insertPoHistoryQuery = ({
  id,
  upc,
  orderItemId,
  orderDate,
  orderQuantity,
  receivedQuantity,
  invoicedQuantity,
  version,
}: PO): string => {
  // datetime from webservices use ISO 8601 with a 'T' (2020-01-06T00:00:00) designating timezone
  // datetime from cache returns with a space between date and timezone (2020-01-06 00:00:00)
  // here we normalizes the correct date format before creating the query
  const orderDateString = orderDate.match('T') ? orderDate.replace('T', ' ') : orderDate;

  return `INSERT INTO PoHistory (Id, Upc, OrdNumId, OrdDate, OrdQty, EInvQty, RecvQty, Version) VALUES (${id}, '${upc}', ${orderItemId}, '${orderDateString}', ${orderQuantity}, ${invoicedQuantity}, ${receivedQuantity}, ${version}) ON CONFLICT(Upc,OrdDate) DO UPDATE SET OrdQty=${orderQuantity}, EInvQty=${invoicedQuantity}, RecvQty=${receivedQuantity}, Version=${version};`;
};

const createPOHistoryInsertQueries = (records: PO[]): string => records.map((record) => insertPoHistoryQuery(record)).join('');

export { getLastOrderItemIdQuery, insertPoHistoryQuery, createPOHistoryInsertQueries };
