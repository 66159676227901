import { NgModule } from '@angular/core';
import { Routes, RouterModule, Route } from '@angular/router';

import { LoginComponent } from 'src/app/pages/login/login.component';
import { HomeComponent } from 'src/app/pages/home/home.component';
import { OrderSubteamComponent } from 'src/app/pages/order-subteam/order-subteam.component';
import { ItemEntryComponent } from 'src/app/pages/item-entry/Item-entry.component';
import { OrderItemDetailComponent } from 'src/app/pages/order-item-detail/order-item-detail.component';
import { OrderItemReviewComponent } from 'src/app/pages/order-item-review/order-item-review.component';
import { CreateCreditComponent } from 'src/app/pages/create-credit/create-credit.component';
import { CreditQuantityComponent } from 'src/app/pages/create-credit/credit-quantity/credit-quantity.component';
import { SavedCreditsComponent } from 'src/app/pages/saved-credits/saved-credits.component';
import { SavedOrdersComponent } from 'src/app/pages/saved-orders/saved-orders.component';
import { ReviewCreditsComponent } from 'src/app/pages/review-credits/review-credits.component';
import { StoreSubteamResolver } from 'src/app/services/resolvers/store-subteam.resolver';
import { SettingsComponent } from 'src/app/pages/settings/settings.component';
import { ItemDetailResolver } from 'src/app/services/resolvers/item-detail.resolver';
import { AuthRouteGuard } from 'src/app/routing/app-route-guard.service';
import { CanDeactivateGuard } from 'src/app/routing/can-deactivate-guard.service';
import { Paths } from 'src/app/constants';

const createSecureRoute = (routeOptions: Route): Route => ({
  ...routeOptions,
  canActivate: [AuthRouteGuard],
});

const routes: Routes = [
  { path: Paths.login, component: LoginComponent },
  { path: Paths.root, redirectTo: Paths.login, pathMatch: 'full' },
  createSecureRoute({ path: Paths.home, component: HomeComponent }),
  createSecureRoute({
    path: `${Paths.orderSubTeam}/:type`,
    component: OrderSubteamComponent,
    resolve: { storeSubteams: StoreSubteamResolver },
  }),
  createSecureRoute({ path: `${Paths.itemEntry}/:type`, component: ItemEntryComponent, canDeactivate: [CanDeactivateGuard] }),
  createSecureRoute({
    path: `${Paths.orderItemDetail}/:upc`,
    component: OrderItemDetailComponent,
    resolve: { itemData: ItemDetailResolver },
    canDeactivate: [CanDeactivateGuard],
  }),
  createSecureRoute({ path: Paths.orderItemReview, component: OrderItemReviewComponent, canDeactivate: [CanDeactivateGuard] }),
  createSecureRoute({
    path: `${Paths.createCredit}`,
    component: CreateCreditComponent,
    canDeactivate: [CanDeactivateGuard],
  }),
  createSecureRoute({
    path: `${Paths.createCreditQuantity}/:upc`,
    component: CreditQuantityComponent,
    resolve: { itemData: ItemDetailResolver },
    canDeactivate: [CanDeactivateGuard],
  }),
  createSecureRoute({ path: Paths.savedCredits, component: SavedCreditsComponent }),
  createSecureRoute({ path: Paths.savedOrders, component: SavedOrdersComponent }),
  createSecureRoute({ path: Paths.reviewCredits, component: ReviewCreditsComponent, canDeactivate: [CanDeactivateGuard] }),
  createSecureRoute({ path: Paths.settings, component: SettingsComponent }),
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
