import { Injectable, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppOptions } from 'src/app/constants';
import { DvoRouterService } from 'src/app/services/dvo-router/dvo-router.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';

import { Subteam } from 'src/app/app.interfaces';

import { Subject } from 'rxjs';

const DVO_SUBTEAM_KEY = `dvo-subteam`;

export const toBool = (val): boolean => val === 'true';

@Injectable({
  providedIn: 'root',
})
export class AppSettingsService {
  private settings = {};
  private settingsSub = new Subject();

  constructor(private route: ActivatedRoute, private storage: LocalStorageService, private router: DvoRouterService) {}

  loadGlobalOptionsFromQueryParams() {
    this.route.queryParams.subscribe((queryParamCollection) => {
      this.settings[AppOptions.ENABLE_LIVE_SERVICES] = toBool(queryParamCollection[AppOptions.ENABLE_LIVE_SERVICES]);
      this.settings[AppOptions.DISABLE_APP_SETTINGS] = toBool(queryParamCollection[AppOptions.DISABLE_APP_SETTINGS]);

      this.settingsSub.next(this.settings);
    });
  }

  shouldEnableLiveServices(): boolean {
    return this.settings[AppOptions.ENABLE_LIVE_SERVICES] || false;
  }

  /**
   * Toggles the setting menu to hide app config
   * from end user
   * Requested by product team
   */
  shouldDisableAppSettings(): boolean {
    {
      return this.settings[AppOptions.DISABLE_APP_SETTINGS] || false;
    }
  }

  onChange() {
    return this.settingsSub;
  }

  setSubteam(subteam: Subteam) {
    this.storage.saveItem(DVO_SUBTEAM_KEY, subteam);
  }

  get subteam() {
    return this.storage.getItem(DVO_SUBTEAM_KEY);
  }

  enableOfflineCacheMode(): Promise<boolean> {
    return this.router.navigateByUrl([], {
      relativeTo: this.route,
      queryParams: {
        ENABLE_LIVE_SERVICES: null,
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false,
    });
  }

  disableOfflineCacheMode(): Promise<boolean> {
    return this.router.navigateByUrl([], {
      relativeTo: this.route,
      queryParams: {
        ENABLE_LIVE_SERVICES: true,
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false,
    });
  }
}
