import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { DVO_SESSION_KEY } from 'src/app/constants';

type AppData = {
  accessToken: string;
  expiresAt: string;
  cacheDB: {
    date: string | null;
    isEnabled: boolean;
    isError: boolean;
  };
};

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private storageSub = new Subject<string>();

  constructor() {}

  watchStorage(): Observable<any> {
    return this.storageSub.asObservable();
  }

  saveItem(key: string, val: any) {
    if (typeof val === 'string') {
      localStorage.setItem(key, val);
    } else {
      localStorage.setItem(key, JSON.stringify(val));
    }

    this.storageSub.next(key);
  }

  getItem(key: string) {
    // the value if an object if we can
    // properly parse it. if parsing fails
    // return the string value stored
    const val = localStorage.getItem(key);
    try {
      return JSON.parse(val);
    } catch (ex) {
      return val;
    }
  }

  deleteItem(key: string) {
    localStorage.removeItem(key);
    this.storageSub.next(key);
  }

  update(key: string, val: any) {
    let newData = null;
    const prevData = this.getItem(key);

    if (Array.isArray(prevData)) {
      newData = [...prevData, val];
    } else {
      newData = {
        ...prevData,
        ...val,
      };
    }

    this.saveItem(key, newData);
  }

  deleteAll() {
    localStorage.clear();
    this.storageSub.next('CLEAR');
  }

  getAppData(): AppData {
    return this.getItem(DVO_SESSION_KEY) || {};
  }

  getToken() {
    const { accessToken = '' } = this.getAppData();
    return accessToken;
  }

  getCacheDBMetaData() {
    const {
      cacheDB = {
        isEnabled: null,
        isError: null,
        date: '',
      },
    } = this.getAppData();
    return cacheDB;
  }
}
