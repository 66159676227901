import { Injectable, isDevMode } from '@angular/core';
import { DVO_CONFIG_KEY } from 'src/app/constants';

declare let window: Window;

@Injectable()
export class AppInitService {
  // !Important: This should return a Promise
  public init() {
    // If running in development, grab the config from the local JSON file
    // otherwise, get the config from the Node server
    const url = isDevMode() ? 'assets/app-config.json' : '/config';

    //Check if there is already values saved for app
    //config to prevent another request going out
    const config = localStorage.getItem(DVO_CONFIG_KEY);

    if (config) {
      const appConfig = JSON.parse(config) as DvoEnvironmentConfig;

      return appConfig;
    }

    return fetch(url)
      .then((response) => response.json())
      .then((config: DvoEnvironmentConfig) => {
        window.config = config;
        localStorage.setItem(DVO_CONFIG_KEY, JSON.stringify(config));
        return config;
      })
      .catch(() => {
        console.error('Failed to obtain the API url from server');
      });
  }
}

export function init_app(appLoadService: AppInitService) {
  const initializer = () => appLoadService.init();
  return initializer;
}
