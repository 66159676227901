import { Injectable } from '@angular/core';
import { ToastController } from '@wfmes/ui-angular';

const DEFAULT_DURATION = 2000;
const DEFAULT_DISPLAY_CLOSE = true;
const DEFAULT_POSITION = 'top';
@Injectable({
  providedIn: 'root'
})

export class ToastService {

  constructor(
    public toastController: ToastController
  ) { }

  async presentToast(
    message?: string,
    position: string = DEFAULT_POSITION,
    duration: number = DEFAULT_DURATION,
    showCloseButton: boolean = DEFAULT_DISPLAY_CLOSE) {
    const toast = await this.toastController.create(
      {
        message,
        position,
        duration,
        showCloseButton,
      });
    toast.present();
  }
}
