import { Injectable } from '@angular/core';

import { LocalStorageService } from 'src/app/services/storage/local-storage.service'
import { EntityType } from 'src/app/app.interfaces';

@Injectable({
  providedIn: 'root'
})
export class SavedDvoEntityService {

  constructor(
    private storage: LocalStorageService,
  ) { }

  updateSave(key: string, val: EntityType): void {
    const id = val.id;
    const prevSave = this.storage.getItem(key) || [];
    let newSave = prevSave;

    // Check if the data already contains a matching save id
    const existingDataIndex = prevSave.findIndex(data => data.id === id);

    if (existingDataIndex > -1) {
      // Update the existing save
      newSave[existingDataIndex] = val;
    } else {
      // Append the new save
      newSave.push(val);
    }

    // Update the localStorage item
    this.storage.saveItem(key, newSave);
  }
}
