import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { DvoHttpClientService } from 'src/app/services/dvo-http/dvo-http-client.service';
import { Order, Credit } from 'src/app/app.interfaces';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(
    private dvoAPI: DvoHttpClientService,
  ) { }

  public submit(order: Order | Credit) {
    return this.dvoAPI.request('POST', 'ordersubmission', {
      body: order,
      observe: 'response',
    }) as Observable<Order | Credit>;
  }

}
