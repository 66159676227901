import { Component, OnInit } from '@angular/core';
import { PopoverController, AlertController } from '@ionic/angular';

import { DvoRouterService } from 'src/app/services/dvo-router/dvo-router.service';
import { SavedOrdersService } from 'src/app/services/saved-orders/saved-orders.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { WarningService } from 'src/app/services/warning/warning.service';
import { Paths } from 'src/app/constants';
import { DvoEntityService } from 'src/app/services/dvo-entity/dvo-entity.service';

@Component({
  selector: 'app-order-review-menu',
  templateUrl: './order-review-menu.component.html',
})
export class OrderReviewMenuComponent implements OnInit {
  constructor(
    private savedOrders: SavedOrdersService,
    private popOver: PopoverController,
    private toast: ToastService,
    private alertController: AlertController,
    private router: DvoRouterService,
    private warning: WarningService,
    private dvoOrder: DvoEntityService
  ) {}

  ngOnInit() {}

  save() {
    const order = this.dvoOrder.current();

    if (order.items.length > 0) {
      this.savedOrders.save(order);
      this.toast.presentToast('DVO Order Saved');
    } else {
      // Do not save orders when no items are added
      this.warning.displayWarning({
        header: 'Items Missing',
        message: 'Empty orders cannot be saved.',
      });
    }

    this.popOver.dismiss('saved');
  }

  async confirmDeleteOrder() {
    const alert = await this.alertController.create({
      header: 'Confirm Delete',
      message: 'Are you sure you would like to delete this order?',
      buttons: [
        {
          text: 'Cancel',
          handler: () => {
            this.popOver.dismiss('canceled');
          },
        },
        {
          text: 'Delete',
          handler: () => {
            this.deleteOrder();
          },
        },
      ],
    });

    await alert.present();
  }

  deleteOrder() {
    try {
      const { id } = this.dvoOrder.current();
      this.dvoOrder.delete();

      if (id) {
        this.savedOrders.deleteOrderyById(id);
      }

      this.popOver.dismiss('deleted');
    } catch (ex) {
      console.log('error deleting order');
      console.log(ex);
    }
  }
}
