import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent implements OnInit {
  @Input() message: string;
  @Input() type: string = 'saved' || 'item';
  imgUrl: string;

  constructor() { }

  ngOnInit() {
    const baseUrl = '/svg'
    switch(this.type){
      case 'saved':
        this.imgUrl = `${baseUrl}/ill-empty.svg`;
        break;
      case 'item':
        this.imgUrl = `${baseUrl}/ill-no-data.svg`;
        break;
      default:
        this.imgUrl = `${baseUrl}/ill-no-data.svg`;
    }
  }

}
