import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { DVO_SAVED_CREDITS_KEY } from 'src/app/constants';
import { SavedDvoEntityService } from 'src/app/services/saved-dvo-entity/saved-dvo-entity.service';
import { EntityType } from 'src/app/app.interfaces';

@Injectable({
  providedIn: 'root'
})
export class SavedCreditsService {
  private storageKey = DVO_SAVED_CREDITS_KEY;

  constructor(
    private storage: LocalStorageService,
    private savedDvoEntity: SavedDvoEntityService,
  ) {}

  getAll(){
    const savedCredits = this.storage.getItem(this.storageKey);
    return savedCredits || []
  }

  save(credit: EntityType){
    const currentSavedCredits = this.storage.getItem(this.storageKey);

    if(!currentSavedCredits){
      this.storage.saveItem(this.storageKey, [credit])
    }else{
      this.savedDvoEntity.updateSave(this.storageKey, credit);
    }
  }

  deleteCredit(credit: EntityType){
    try{
      const savedCredits = this.getAll();
      const updatedCredits = savedCredits.filter(dvoCredit => dvoCredit.id !== credit.id);

      this.storage.deleteItem(this.storageKey);
      this.storage.saveItem(this.storageKey, updatedCredits)
    return updatedCredits
    }catch(ex){
      console.error(ex)
    }
  }

  getTotal() {
    const savedCredits = this.storage.getItem(DVO_SAVED_CREDITS_KEY);
    return savedCredits && savedCredits.length ? savedCredits.length : 0;
  }

  deleteOldCredits(): void {
    const now = moment();
    this.getAll().forEach((credit: EntityType) => {
      const createdOn = moment(credit.createdOn, 'MMMM Do YYYY, h:mm:ss a');
      if((now.diff(createdOn, 'hours') > 48)) {
        this.deleteCredit(credit);
      }
    })
  }


  deleteCreditById(id: string){
    const updatedSavedCredits = this.getAll().filter(
      credit => credit.id !== id
    )
    this.storage.saveItem(this.storageKey, updatedSavedCredits)
  }
}
