import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import * as moment from 'moment';

import { DvoRouterService } from 'src/app/services/dvo-router/dvo-router.service';
import { Subteam } from 'src/app/app.interfaces';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { DVO_ENTITY_KEY, Paths, ORDER, CREDIT } from 'src/app/constants';
import { UserService } from 'src/app/services/user/user.service';
import getNewGuid from 'src/app/utils/guid.util';

@Component({
  selector: 'app-order-subteam',
  templateUrl: './order-subteam.component.html',
  styleUrls: ['./order-subteam.component.scss'],
})
export class OrderSubteamComponent implements OnInit {
  public searchTerm: string;
  public subteams;
  private allSubteams;
  public selectedTeam: Subteam;
  public type: string;

  constructor(
    private router: DvoRouterService,
    private activatedRoute: ActivatedRoute,
    private store: LocalStorageService,
    private user: UserService
  ) {}

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((paramMap: ParamMap) => {
      this.type = paramMap.get('type');
      this.allSubteams = this.activatedRoute.snapshot.data.storeSubteams;
      this.subteams = this.allSubteams;
    });
  }

  setSelected = (evt: UIEvent) => {
    this.selectedTeam = (evt.detail as any) as Subteam;
  };

  filterItems = (evt: UIEvent) => {
    const searchTerm = (evt.srcElement as HTMLInputElement).value;

    if (!searchTerm) {
      this.subteams = this.allSubteams;
      return;
    }

    this.subteams = this.allSubteams.filter((subteam) => {
      if (subteam.name.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1) {
        return true;
      }
      return false;
    });
  };

  continue = () => {
    //Create a new "ENTITY (Credit or Order)" object in localstroage to track the ongoing
    //updates to the order
    this.store.saveItem(DVO_ENTITY_KEY, {
      id: getNewGuid(),
      entityType: this.type,
      subteam: this.selectedTeam,
      items: [],
      createdBy: this.user.getUsername(),
      createdOn: moment().format('MMMM Do YYYY, h:mm:ss a'),
    });

    if (this.type === ORDER) {
      this.router.navigateByUrl(`${Paths.itemEntry}/${this.type}`);
    }
    if (this.type === CREDIT) {
      this.router.navigateByUrl(`${Paths.createCredit}`).catch((err) => console.error(err));
    }
  };
}
