import { Injectable } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent, NavigationExtras } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { Paths } from 'src/app/constants';

@Injectable({
  providedIn: 'root',
})
export class DvoRouterService {
  private whiteListedLoadingRoutes = [Paths.orderSubTeam, Paths.orderItemDetail, Paths.createCreditQuantity];

  shouldRouteDisplayLoading(routeEvent: RouterEvent): boolean {
    const targetUrl = routeEvent.url;

    let isFound = false;

    for (let i = 0; i < this.whiteListedLoadingRoutes.length; i++) {
      const path = this.whiteListedLoadingRoutes[i];
      const regx = new RegExp(`\\/${path}`, 'gm');

      isFound = regx.test(targetUrl);
      if (isFound) {
        return true;
      }
    }

    return false;
  }

  constructor(private router: Router, private alertController: AlertController) {
    this.router.events.subscribe(async (event: RouterEvent) => {
      switch (true) {
        case event instanceof NavigationStart: {
          if (this.shouldRouteDisplayLoading(event)) {
            this.displayPageLoading();
          }
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel: {
          if (this.shouldRouteDisplayLoading(event)) {
            // cacheDB calls can return so quickly
            // it can cause a race condition regarding
            // the first time a dialog/overlay controller
            // is attached to the DOM and then dispayed.
            try {
              await this.hidePageLoading();
            } catch (ex) {
              if (ex === 'overlay does not exist') {
                // in the event a race is hit
                // introduce a small delay and try again
                // in order to let the first overlay controller
                // attach itself.
                setTimeout(() => {
                  this.hidePageLoading();
                }, 1000);
              }
            }
          }
          break;
        }
        case event instanceof NavigationError: {
          this.hidePageLoading();
          //@ts-ignore
          const err = event.error;
          this.displayErr(err);
        }
        default: {
          break;
        }
      }
    });
  }

  navigateByUrl(path: string | string[], options: NavigationExtras = {}): Promise<boolean> {
    const defaultOptions = {
      queryParamsHandling: 'preserve',
    } as NavigationExtras;

    const navOptions = {
      ...defaultOptions,
      ...options,
    };
    const navPath = Array.isArray(path) ? path : [path];

    return this.router.navigate(navPath, navOptions);
  }

  async displayPageLoading(): Promise<void> {
    const alert = await this.alertController.create({
      id: 'dvo-loading',
      message: '<span style="text-align: center">Loading...</span>',
      buttons: [],
    });

    alert.present();
  }

  hidePageLoading() {
    return this.alertController.dismiss(null, null, 'dvo-loading');
  }

  async displayErr(errMessage: string) {
    const errAlert = await this.alertController.create({
      header: 'Error',
      message: errMessage,
      buttons: ['OK'],
    });

    await errAlert.present();
  }
}
