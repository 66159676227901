import { Component, OnInit, OnDestroy } from '@angular/core';
import { AlertController, MenuController, PopoverController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';

import { DvoRouterService } from 'src/app/services/dvo-router/dvo-router.service';
import { OrderService } from 'src/app/services/order/order.service';
import { CreditReviewMenuComponent } from './credit-review-menu/credit-review-menu.component';
import { UserService } from 'src/app/services/user/user.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { Credit, Subteam, EntityType, Item } from 'src/app/app.interfaces';
import { SavedCreditsService } from 'src/app/services/saved-credits/saved-credits-service.service';
import { WarningService } from 'src/app/services/warning/warning.service';
import { DvoEntityService } from 'src/app/services/dvo-entity/dvo-entity.service';
import { AuthService } from 'src/app/services/auth/auth.service';

import { Paths, CREDIT, DVO_ENTITY_KEY } from 'src/app/constants';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';

@Component({
  selector: 'app-review-credits',
  templateUrl: './review-credits.component.html',
  styleUrls: ['./review-credits.component.scss'],
})
export class ReviewCreditsComponent implements OnInit, OnDestroy {
  public credit: EntityType;
  public subteam: Subteam;
  public totalItemsScannedNum: number = 0;
  public totalCasesScannedNum: number = 0;
  public selectedReason: string;
  private storageSub = new Subscription();
  private shouldActivateNavGuard = true;
  isLoading = false;

  constructor(
    private router: DvoRouterService,
    private orderService: OrderService,
    public alertController: AlertController,
    private menuCtrl: MenuController,
    private popoverCtrl: PopoverController,
    private user: UserService,
    private toast: ToastService,
    private savedCredits: SavedCreditsService,
    private warning: WarningService,
    private dvoCredit: DvoEntityService,
    private storage: LocalStorageService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.credit = this.dvoCredit.current();
    this.subteam = this.dvoCredit.getCurrentSubTeam();
    this.totalItemsScannedNum = this.dvoCredit.getTotalItemsScanned();
    this.totalCasesScannedNum = this.dvoCredit.getTotalCasesScanned();
    this.selectedReason = this.dvoCredit.getReason();

    this.storageSub = this.storage.watchStorage().subscribe((storageKey: string) => {
      if (storageKey === DVO_ENTITY_KEY) {
        this.credit = this.dvoCredit.current();
        this.subteam = this.dvoCredit.getCurrentSubTeam();
        this.totalItemsScannedNum = this.dvoCredit.getTotalItemsScanned();
        this.totalCasesScannedNum = this.dvoCredit.getTotalCasesScanned();
        this.selectedReason = this.dvoCredit.getReason();
      }
    });

    const routeState = this.activatedRoute.paramMap.pipe(map(() => window.history.state));

    routeState.subscribe(() => {
      // By piggybacking off this subscription we can reset the nav guard
      // upon subsequent route changes
      this.shouldActivateNavGuard = true;
    });

    this.authService.onUserAuthChange().subscribe((isLoggedIn) => {
      if (!isLoggedIn) {
        this.shouldActivateNavGuard = false;
      }
    });
  }

  ngOnDestroy() {
    this.storageSub.unsubscribe();
  }

  /**
   * @description Method used by CanDeactivateGuard to block navigation
   * changes in the event the user has not saved the item.
   */
  canDeactivate(): Promise<boolean> | boolean {
    if (this.shouldActivateNavGuard) {
      return this.confirmNavigateAway();
    }

    return true;
  }

  async confirmNavigateAway(): Promise<boolean> {
    return new Promise(async (resolve) => {
      const alert = await this.alertController.create({
        header: 'Unsaved Item',
        message: `Leaving the active order window without first Saving or Sending will result in the loss of your order`,
        buttons: [
          {
            text: 'OK',
            handler: () => {
              alert.dismiss();
              resolve(true);
            },
          },
          {
            text: 'Cancel',
            handler: () => {
              alert.dismiss();
              resolve(false);
            },
          },
        ],
      });

      await alert.present();
    });
  }

  sendCredit() {
    const { subteam } = this.credit;

    if (this.credit.items.length > 0) {
      this.isLoading = true;
      this.shouldActivateNavGuard = false;
      const creditPayload: Credit = {
        orderType: 1,
        storeNumber: subteam.storeId,
        subTeamId: subteam.subTeamId,
        departmentId: subteam.departmentId,
        userId: this.user.getUserId(),
        items: this.credit.items.map(({ upc, quantity }) => ({ upc, quantity, reason: this.selectedReason })),
      };

      this.orderService.submit(creditPayload).subscribe(
        (response) => {
          this.isLoading = false;
          this.displaySuccess();
        },
        (error) => {
          this.isLoading = false;
          this.displayFailure();
        }
      );
    } else {
      // Do not send credits when no items are added
      this.warning.displayWarning({
        header: 'Items Missing',
        message: 'Empty credits cannot be sent.',
      });
    }
  }

  async displayReviewCreditOptions(ev: MouseEvent) {
    const popover = await this.popoverCtrl.create({
      component: CreditReviewMenuComponent,
      event: ev,
      translucent: true,
    });

    await popover.present();

    const { data: completedAction } = await popover.onDidDismiss();

    switch (completedAction) {
      case 'saved':
      case 'deleted':
        this.shouldActivateNavGuard = false;
        this.router.navigateByUrl(Paths.home);
        break;
      case 'change reason':
        this.shouldActivateNavGuard = false;
        this.router.navigateByUrl(Paths.createCredit, {
          state: {
            shouldNavBackToReview: true,
          },
        });
        break;
    }
  }

  displayMenu() {
    this.menuCtrl.open();
  }

  async confirmDelete(event: MouseEvent, item: Item) {
    event.stopPropagation();

    const alert = await this.alertController.create({
      header: 'Confirm Delete',
      message: `Are you sure you would like to delete the following credit item? <br></br> ${item.description}`,
      buttons: [
        'Cancel',
        {
          text: 'Confirm',
          handler: () => {
            this.deleteItem(item);
          },
        },
      ],
      backdropDismiss: false,
    });

    await alert.present();
  }

  async deleteItem(item: Item) {
    const { description, upc } = item;
    const items = await this.dvoCredit.deleteItemByUPC(upc);
    if (items.length) {
      this.credit.items = items;
      this.toast.presentToast(`${description} Deleted`);
      this.totalItemsScannedNum = this.dvoCredit.getTotalItemsScanned();
      this.totalCasesScannedNum = this.dvoCredit.getTotalCasesScanned();
    }
  }

  async displaySuccess() {
    const alert = await this.alertController.create({
      header: 'Success',
      subHeader: 'Create Credit',
      message: 'Your DVO credit was successfully submitted.',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.completeCreditOrder();
          },
        },
      ],
      backdropDismiss: false,
    });

    await alert.present();
  }

  async displayFailure() {
    const alert = await this.alertController.create({
      header: 'Failure',
      subHeader: 'Create Credit',
      message:
        'We had trouble submitting your DVO credit. <br></br> Please ensure you have a network connection or save your work try again at a later time',
      buttons: ['OK', { text: 'Save', handler: () => this.saveCredit() }],
      backdropDismiss: false,
    });

    await alert.present();
  }

  completeCreditOrder() {
    const { id: savedCreditId } = this.credit;

    if (savedCreditId) {
      this.savedCredits.deleteCreditById(savedCreditId);
      this.dvoCredit.delete();
    }
    this.router.navigateByUrl(Paths.home);
  }

  async saveCredit() {
    const credit = this.dvoCredit.current();

    this.savedCredits.save(credit);

    this.toast.presentToast('DVO Credit Saved');
  }

  scanItem() {
    this.shouldActivateNavGuard = false;
    this.router.navigateByUrl(`${Paths.itemEntry}/${CREDIT}`, { state: { shouldActivateNavGuard: true } });
  }

  reviewItemDetails(item: Item) {
    this.shouldActivateNavGuard = false;
    this.router.navigateByUrl(`/${Paths.createCreditQuantity}/${item.upc}`, {
      state: {
        shouldNavBackToReview: true,
        item,
      },
    });
  }
}
