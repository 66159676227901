import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { ItemService } from 'src/app/services/item/item.service';
import { Item } from 'src/app/app.interfaces';

@Injectable({
  providedIn: 'root'
})
export class ItemDetailResolver implements Resolve<Item> {

  constructor(
    private itemService: ItemService,
  ) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.itemService.getItemDetails(route.paramMap.get('upc'))
  }
}
