import { Component, OnInit, OnDestroy } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Subscription } from 'rxjs';

import { MenuController, AlertController, ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AppSettingsService } from 'src/app/services/app-settings/app-settings.service';
import { DvoRouterService } from 'src/app/services/dvo-router/dvo-router.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { SavedOrdersService } from 'src/app/services/saved-orders/saved-orders.service';
import { SavedCreditsService } from 'src/app/services/saved-credits/saved-credits-service.service';
import { PoHistoryService } from 'src/app/services/po-history/po-history.service';
import {
  Paths,
  CREDIT,
  ORDER,
  DVO_SAVED_CREDITS_KEY,
  DVO_SAVED_ORDERS_KEY,
  MAJOR_VERSION,
  DVOKeyName,
  VersionKey,
  BUILD_ID,
} from 'src/app/constants';
import { HelpComponent } from 'src/app/components/help/help.component';
// key data that should be retained
// between new versions of the application
const MIGRANT_KEYS = [DVOKeyName.SAVED_ORDERS, DVOKeyName.SAVED_CREDITS];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public totalSavedOrdersNum: number = 0;
  public totalSavedCreditsNum: number = 0;
  public shouldDisplaySettings: boolean = true;
  private storageSub = new Subscription();

  constructor(
    public appSettings: AppSettingsService,
    private router: DvoRouterService,
    private menu: MenuController,
    private auth: AuthService,
    private storage: LocalStorageService,
    private savedOrders: SavedOrdersService,
    private savedCredits: SavedCreditsService,
    private poHistory: PoHistoryService,
    private alertController: AlertController,
    private modalController: ModalController,
    private updates: SwUpdate
  ) {
    appSettings.loadGlobalOptionsFromQueryParams();

    updates.available.subscribe((event) => {
      console.log('update avaialble');
      console.log(event);

      this.displayAppUpdate();
    });
    updates.activated.subscribe((event) => {
      console.log('update activated');
      console.log(event);
    });
  }

  ngOnInit() {
    this.totalSavedOrdersNum = this.savedOrders.getTotal();
    this.totalSavedCreditsNum = this.savedCredits.getTotal();

    this.appSettings.onChange().subscribe(
      () => {
        this.shouldDisplaySettings = !this.appSettings.shouldDisableAppSettings();
      },
      () => {},
      () => {}
    );

    this.storageSub = this.storage.watchStorage().subscribe((data: string) => {
      switch (data) {
        case DVO_SAVED_ORDERS_KEY: {
          this.totalSavedOrdersNum = this.savedOrders.getTotal();
          break;
        }
        case DVO_SAVED_CREDITS_KEY: {
          this.totalSavedCreditsNum = this.savedCredits.getTotal();
          break;
        }
        case 'CLEAR': {
          this.totalSavedOrdersNum = this.savedOrders.getTotal();
          this.totalSavedCreditsNum = this.savedCredits.getTotal();
          break;
        }
        default:
          break;
      }
    });
  }

  async displayAppUpdate() {
    const alert = await this.alertController.create({
      header: 'Application Update',
      message: 'Please restart the application to download the latest updates',
      buttons: [
        {
          text: 'OK',
          handler: async () => {
            //Purge old keys from previous MAJOR versions
            await this.prugeOldVersionKeys();
            document.location.reload();
          },
        },
      ],
    });

    await alert.present();
  }

  getMigrantKeyName(key: string) {
    // search returns the index instead of a boolean
    // if the whitelisted key is present it will return 0 as the index
    // whick would normally be false if used as a boolean value
    const containsKey = (v) => !Boolean(key.search(v));

    if (containsKey(DVOKeyName.SAVED_ORDERS)) return DVOKeyName.SAVED_ORDERS;
    if (containsKey(DVOKeyName.SAVED_CREDITS)) return DVOKeyName.SAVED_CREDITS;

    return null;
  }

  async prugeOldVersionKeys() {
    return new Promise((resolve, reject) => {
      try {
        const appKeys = Object.keys(localStorage);

        appKeys.forEach((key) => {
          const keyVersionRegEx = /\d+$/;
          const appKeyVersion = Number(keyVersionRegEx.exec(key));
          const currentVersion = Number(BUILD_ID);

          // If the idenfitying version number is less than
          // the new current check to see which keys can
          // be mirgated to the new version (saved order & saved credits)
          if (appKeyVersion < currentVersion) {
            const shouldKeyDataMigrate = Boolean(this.getMigrantKeyName(key));

            if (shouldKeyDataMigrate) {
              this.storage.saveItem(VersionKey(this.getMigrantKeyName(key)), localStorage[key]);
            }

            // purge any legacy or non mirgant keys
            this.storage.deleteItem(key);
          }
        });

        resolve();
      } catch (ex) {
        reject(ex);
      }
    });
  }

  ngOnDestroy() {
    this.storageSub.unsubscribe();
  }

  navToHome() {
    this.router.navigateByUrl(Paths.home);
    this.closeMenu();
  }

  closeMenu() {
    this.menu.close();
  }

  logout() {
    this.auth.confirmLogout();
    this.closeMenu();
  }

  settings(): void {
    this.router.navigateByUrl(Paths.settings);
    this.closeMenu();
  }

  async help() {
    const modal = await this.modalController.create({
      component: HelpComponent,
    });
    return await modal.present();
  }

  createOrders(): void {
    this.router.navigateByUrl(`${Paths.orderSubTeam}/${ORDER}`);
    this.closeMenu();
  }

  createCredits(): void {
    this.router.navigateByUrl(`${Paths.orderSubTeam}/${CREDIT}`);
    this.closeMenu();
  }

  navToOrderSubteam() {
    this.router.navigateByUrl('order-subteam');
    this.closeMenu();
  }

  navToOrderItemDetail() {
    this.router.navigateByUrl('order-item-detail');
    this.closeMenu();
  }

  navToOrderItemReview() {
    this.router.navigateByUrl('order-item-review');
    this.closeMenu();
  }
  navToSavedOrders() {
    this.router.navigateByUrl(Paths.savedOrders);
    this.closeMenu();
  }

  navToSavedCredits() {
    this.router.navigateByUrl(Paths.savedCredits);
    this.closeMenu();
  }

  updatePoHistory() {
    this.poHistory.updatePOHistory();
    this.closeMenu();
  }
}
