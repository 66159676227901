import { Injectable, NgZone } from '@angular/core';
import { DvoHttpClientService } from 'src/app/services/dvo-http/dvo-http-client.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { Observable, OperatorFunction } from 'rxjs';

import { CacheDB } from '@wfmes/mobile';
import { environment } from 'src/environments/environment';

import * as LogRocket from 'logrocket';

@Injectable({
  providedIn: 'root',
})
export class AppDBService {
  constructor(private dvoHttp: DvoHttpClientService, private storage: LocalStorageService, private zone: NgZone) { }

  //Since our wfm-mobile-js events happen
  //outside the execution context of Angular
  //we need to run cacheDB processes in a zone
  //via NgZone. This lets Angular know when something
  //has completed which then triggers change detection
  //to render the view
  runInZone<T>(zone: NgZone): OperatorFunction<T, T> {
    return (source) => {
      return new Observable((observer) => {
        const onNext = (value: T) => zone.run(() => observer.next(value));
        const onError = (e: any) => zone.run(() => observer.error(e));
        const onComplete = () => zone.run(() => observer.complete());
        return source.subscribe(onNext, onError, onComplete);
      });
    };
  }

  private _initCache(): Observable<any> {
    return new Observable((observer) => {
      const req = this.dvoHttp.request('GET', 'Store/CacheUrl', {});

      req.subscribe(
        ({ cacheUrl }) => {
          const cacheURL = `${environment.baseUrl}${cacheUrl}`;
          const token = this.storage.getToken();

          try {
            CacheDB.hydrateFromURL(cacheURL, token, () => {
              observer.next();
              observer.complete();
            });
          } catch (ex) {
            observer.error(`Error hyrdrating CacheDB: ${ex}`);

            LogRocket.captureException(ex);
          }
        },
        (err) => {
          observer.error(`Error fetching CacheUrl : ${err}`);
        }
      );
    });
  }

  private _query(sqlQuery: string): Observable<any> {
    console.info(`SQLLite DB Query: ${sqlQuery}`);
    return new Observable((observer) => {
      try {
        CacheDB.query(sqlQuery, (resp: any) => {
          observer.next(JSON.parse(resp.Data));
          observer.complete();
        });
      } catch (ex) {
        observer.error(`Error on SqlLite DB query" ${ex}`);

        LogRocket.captureException(ex);
      }
    });
  }

  initCache(): Observable<any> {
    return this._initCache().pipe(this.runInZone(this.zone));
  }

  query(sqlQuery: string): Observable<any> {
    return this._query(sqlQuery).pipe(this.runInZone(this.zone));
  }
}
