import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
// import { Keepalive } from '@ng-idle/keepalive'; <<< keep a server session alive https://www.npmjs.com/package/@ng-idle/keepalive

import { TimeoutModalComponent } from 'src/app/modals/timeout-modal.component';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-timeout',
  templateUrl: './timeout.component.html',
  styleUrls: ['./timeout.component.scss']
})
export class TimeoutComponent implements OnInit {
  // Idle state and timer function for timeout
  idleState = 'Not started';
  idleTime = 60 * 30; // 30 minutes
  timeoutTime = 10;
  timeoutMsg;
  shouldActivateIdleWatch: boolean = true;

  constructor(
    private idle: Idle,
    private auth: AuthService,
    private modalCtrl: ModalController,
  ) {}

  ngOnInit() {
    // Idle Timeout Setup
    if(this.shouldActivateIdleWatch){
      this.idle.setIdle(this.idleTime); // Allowed application idle time before timeout modal
      this.idle.setTimeout(this.timeoutTime); // Timeout counter that appears in modal for action before auto logout
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // Sets input sources as interrupts to reset idle

      this.idle.onIdleStart.subscribe(() => {
        this.idleState = 'Idle';
        this.displayTimeoutModal();
      });

      this.idle.onIdleEnd.subscribe(() => {
        this.idleState = 'Active';
        this.reload();
      });

      this.idle.onTimeout.subscribe(() => {
        this.idleState = 'Timed Out!';
        this.handleTimeout();
      });

      this.idle.onTimeoutWarning.subscribe((countdown) => {
        this.timeoutMsg = `You will be logged out in ${countdown} seconds.`;
      });

      this.auth.onUserAuthChange().subscribe((isLoggedIn) => {
        if (isLoggedIn) {
          this.idle.watch();
        } else {
          this.idle.stop();
        }
      });
    }
  }

  reload() {
    this.idle.watch();
  }

  handleTimeout() {
    this.modalCtrl.dismiss();

    // The "inactive" flag is what tells the login service
    // that the logout was a session timeout due to inactivity
    this.auth.logout({ inactive: true });
  }

  stayLoggedIn(keepLoggedIn: boolean) {
    if (!keepLoggedIn) {
      this.modalCtrl.dismiss();
    } else {
      this.reload();
    }
  }

  async displayTimeoutModal() {
    const modal = await this.modalCtrl.create({
      component: TimeoutModalComponent,
      componentProps: {
        idleState: this.idleState
      }
    });

    modal.onWillDismiss()
      .then(({ data }) => {
        if (data !== undefined) {
          this.stayLoggedIn(data);
        }
      })
      .catch((err) => { throw new Error(err); });

    return await modal.present();
  }
}
