import { Injectable } from '@angular/core';
import { AppDBService } from 'src/app/services/app-db/app-db.service';
import { DvoHttpClientService } from 'src/app/services/dvo-http/dvo-http-client.service';
import { AlertController } from '@ionic/angular';
import { Subscription, timer, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { getLastOrderItemIdQuery, createPOHistoryInsertQueries } from 'src/app/services/po-history/sql';
import { PO } from 'src/app/app.interfaces';
@Injectable({
  providedIn: 'root',
})
export class PoHistoryService {
  poHistoryInterval: Observable<number>;
  poHistoryIntervalSub: Subscription;
  interval: number;
  manualPoHistoryRequest = true;

  constructor(private dvoAPI: DvoHttpClientService, private cache: AppDBService, private alertController: AlertController) {}

  private getLastOrderItemId(): Observable<number> {
    return this.cache.query(getLastOrderItemIdQuery()).pipe(
      map((resp) => {
        const [version] = resp;
        const versionId = version['max(version)'];

        if (versionId) {
          return versionId;
        }

        // if there are no updates value
        // should default to 0 to indicate to the
        // service responsible to fetch new updates
        // `/pohistoriesafterversion/{version}`
        return 0;
      })
    );
  }

  //TODO: update lastOrderItemId to version
  private getLatestPoHistoryRecords(): Observable<PO[]> {
    return this.getLastOrderItemId().pipe(
      mergeMap((versionId) => {
        console.info(`fetching latest po history records from web service via version number: ${versionId}`);
        if (!navigator.onLine) {
          throw new Error('Device is Offline');
        }
        if (Number(versionId) >= 0) {
          return this.dvoAPI.request('GET', `store/pohistoriesafterversion/${versionId}`);
        } else {
          throw new Error('VersionID is not compatible');
        }
      })
    );
  }

  updatePOHistory() {
    this.getLatestPoHistoryRecords().subscribe(
      (newRecords: PO[]) => {
        this.addNewPoHistoryRecordsToCache(newRecords);
      },
      (err) => {
        console.error(err);
        if (this.manualPoHistoryRequest) {
          this.displayAlert('Failure', 'Could not Update PO History', JSON.stringify(err), ['OK']);
        }
      }
    );
  }

  private addNewPoHistoryRecordsToCache(newRecordsToAdd: PO[]) {
    console.info('DOV MOBILE: PO History Update Starting');

    if (!newRecordsToAdd.length) {
      this.displayAlert('Info', 'No new transactions found', 'You appear to have the latest purchase order history', ['OK']);
      return;
    }

    const insertQueries = createPOHistoryInsertQueries(newRecordsToAdd);

    this.cache.query(insertQueries).subscribe(
      (result) => console.log(result),
      (err) => {
        console.error('DVO MOBILE: PO History Update Failed');
        console.error(err);
        if (this.manualPoHistoryRequest) {
          this.displayAlert('Failure', 'Could not Update PO History', JSON.stringify(err), ['OK']);
        }
      },
      () => {
        if (this.manualPoHistoryRequest) {
          this.displayAlert('Success', 'Update PO History', 'PO history was succesfully updated', ['OK']);
        }
        this.manualPoHistoryRequest = true;
        console.info('DOV MOBILE: PO History Update Complate - Success');
      }
    );
  }

  setPOHistoryInterval(userInterval: number = 30) {
    this.interval = userInterval; // Store the user defined interval locally if provided so service is resumed at same frequency
    this.poHistoryInterval = timer(this.interval * 60 * 1000, this.interval * 60 * 1000);

    this.poHistoryIntervalSub = this.poHistoryInterval.subscribe(() => {
      this.manualPoHistoryRequest = false; // Set to false so that popups are not displayed to the user in auto update mode
      this.updatePOHistory();
    });
  }

  clearPOHistoryInterval() {
    this.poHistoryIntervalSub.unsubscribe();
  }

  async displayAlert(header: string, subHeader: string, message: string, buttons) {
    const alert = await this.alertController.create({
      header,
      subHeader,
      message,
      buttons,
      backdropDismiss: false,
    });

    await alert.present().then(() => {
      console.info('Subscription Paused');
      this.clearPOHistoryInterval();
    });

    alert.onDidDismiss().then(() => {
      this.setPOHistoryInterval(this.interval);
      console.info('Subscription Resumed');
    });
  }
}
