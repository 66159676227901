import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse, HttpParams } from '@angular/common/http';
import { throwError, Observable, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { createAPIResourceURL } from 'src/app/utils/createResourceURL';

type RequestType = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'JSONP';
type HttpOptions = {
  body?: any;
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  observe?: 'body' | 'response';
  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };
  responseType?: 'text' | 'blob' | 'arraybuffer' | 'json';
  reportProgress?: boolean;
  withCredentials?: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class DvoHttpClientService {
  // Create a subject that other services and components can subscribe to and monitor the error response
  // In the future this could probably be broken out into a larger interceptor to handle the req/res
  public onError = new Subject<{ errorResponseCode: number; message: string }>();

  constructor(private http: HttpClient) {}

  //this mainly provides a first layer to handleing
  //network request but ultimately throws the error response
  //back out for any other services to decern the user facing
  //message rather than a blanketed "Didn't work, try again"
  private handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ErrorEvent) {
      console.error('An error occurred:', errorResponse.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      this.onError.next({
        errorResponseCode: errorResponse.status,
        message: errorResponse.message,
      });

      console.error(`Failed Request - Status: ${errorResponse.status}, ` + `Server message: ${errorResponse.message}`);
    }

    return throwError(errorResponse);
  }

  request(requestType: RequestType, path: string, httpOptions: HttpOptions = {}): Observable<any> {
    return this.http.request(requestType, createAPIResourceURL(path), httpOptions).pipe(catchError(this.handleError.bind(this)));
  }
}
