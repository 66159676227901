import { environment } from 'src/environments/environment';

export const getBaseURL = (): string => (
  environment.baseUrl
)

export const getAPIHost = (): string => {
  //gets hostname from http://<hostname>:<port>
  const host = getBaseURL().split('//')[1];
  return host.toLocaleLowerCase()
}

export const createAPIResourceURL = (path: string): string => (
  `${getBaseURL()}/api/${path}`
)
