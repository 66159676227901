import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { DVO_SAVED_ORDERS_KEY } from 'src/app/constants';
import { SavedDvoEntityService } from 'src/app/services/saved-dvo-entity/saved-dvo-entity.service';
import { EntityType } from 'src/app/app.interfaces';

@Injectable({
  providedIn: 'root'
})
export class SavedOrdersService {
  private storageKey = DVO_SAVED_ORDERS_KEY;

  constructor(
    private storage: LocalStorageService,
    private savedDvoEntity: SavedDvoEntityService,
  ) {}

  getAll(): EntityType[]{
    const savedOrders = this.storage.getItem(this.storageKey);
    return savedOrders || []
  }

  save(order: EntityType){
    const currentSavedOrders = this.storage.getItem(this.storageKey);

    if(!currentSavedOrders){
      this.storage.saveItem(this.storageKey, [order])
    }else{
      this.savedDvoEntity.updateSave(this.storageKey, order);
    }
  }

  deleteOrder(order: EntityType){
    try{
      const savedOrders = this.getAll();
      const updatedOrders = savedOrders.filter(dvoOrder => dvoOrder.id !== order.id);

      this.storage.deleteItem(this.storageKey);
      this.storage.saveItem(this.storageKey, updatedOrders)
      return updatedOrders
    }catch(ex){
      console.error(ex)
    }
  }

  getTotal() {
    const savedOrders = this.storage.getItem(DVO_SAVED_ORDERS_KEY);
    return savedOrders && savedOrders.length ? savedOrders.length : 0;
  }

  // This function can be called at user login to go through all
  // orders saved on device and purge them if created over 48hrs ago
  deleteOldOrders(): void {
    const now = moment();
    this.getAll().forEach((order: EntityType) => {
      const createdOn = moment(order.createdOn, 'MMMM Do YYYY, h:mm:ss a');
      if((now.diff(createdOn, 'hours') > 48)) {
        this.deleteOrder(order);
      }
    })
  }

  deleteOrderyById(id: string){
    const updatedSavedOrders = this.getAll().filter(
      order => order.id !== id
    )
    this.storage.saveItem(this.storageKey, updatedSavedOrders)
  }

}
