// This utility takes in the data from the bff item service and parses it out into the format needed to display on the item detail page
import { Item, ItemHistory, WebItem, CacheDBItem } from 'src/app/app.interfaces';
import { getDayRangeFromWeek, formatItemDate } from 'src/app/utils/dateTime';
import * as moment from 'moment';

export function formatItemInfoForDisplay(item: WebItem | CacheDBItem): Item {
  const itemDetailDefault = {
    history: [] as ItemHistory[],
    quantity: 1,
  };

  //pluck out properties 'itemMovements'
  //and 'purchaseOrderHistory' as these are
  //combined into a .history property
  const { itemMovements: itemMovementData = [], purchaseOrderHistory: poHistoryData = [], ...itemDetailsToDisplay } = item;

  const displayItem = {
    ...itemDetailDefault,
    ...itemDetailsToDisplay,
  } as Item;
  /**
   * Create Rolling 14 day window as item 'history'
   */

  // clear array of history so existing items don't display duplicate data
  displayItem.history = [];
  const roundToOneDecimal = (number) => (Math.round(number) === number ? number : number.toFixed(1));
  const getPoHistoryDataForDate = (date) => poHistoryData.find((record) => formatItemDate(record.orderDate) === date);
  const getMvmtDataForDate = (date) => itemMovementData.find((record) => formatItemDate(record.date) === date);
  const lastTwoWeekRange = getDayRangeFromWeek(2, 14);

  lastTwoWeekRange.forEach((daySlug) => {
    let poHistory = getPoHistoryDataForDate(daySlug);
    let mvmt = getMvmtDataForDate(daySlug);

    const { averageUnits = null, totalUnits = null, averageCases = null, totalCases = null } = mvmt || {};

    const { orderQuantity = null, invoicedQuantity = null, receivedQuantity = null } = poHistory || {};

    const recordData = {
      day: moment(daySlug).format('ddd MM/DD'),
      avgUnits: (averageUnits && roundToOneDecimal(Number(averageUnits))) || 0,
      totalUnits: (totalUnits && roundToOneDecimal(Number(totalUnits))) || 0,
      avgCases: (averageCases && roundToOneDecimal(Number(averageCases))) || 0,
      totalCases: (totalCases && roundToOneDecimal(Number(totalCases))) || 0,

      // Business requirement is to display nothing vs 0 for empty poHistory
      // so we used undefined as default values
      orderQty: orderQuantity && roundToOneDecimal(Number(orderQuantity)),
      invoicedQty: invoicedQuantity && roundToOneDecimal(Number(invoicedQuantity)),
      receivedQty: receivedQuantity && roundToOneDecimal(Number(receivedQuantity)),
    };

    const record = {
      ...recordData,
      hasOrderErr: isRecordInvalid(recordData),
    };

    displayItem.history.push(record);
  });

  return displayItem;
}

/**
 * data saved as 'NULL' in a cacheDB
 * comes back as empty strings as by design
 * from wfm-native-app
 * https://gitlab.a01.wfmcld.com/wfm-mobile/native-host-app/-/blob/master/src/WFM.Mobile.Modules.Caching/SqlLite/SqlLiteDb.cs
 *
 * NULL values have meaning in DVO, being that the order is
 * not yet acted upon/processed.
 *
 * Values from webservices would be `null`. This small util
 * acts as a catch all for any variety of null/''.
 */
const isPendingProcessing = (value): boolean => {
  switch (value) {
    case null:
      return true;
    case 'null':
      return true;
    case '':
      return true;
    case 'NULL':
      return true;
    default:
      return false;
  }
};

/**
 * PO History data can display errors in ordering, such as a buyer
 * recieving less than ordered or invoiced less than what was ordered
 *
 * null = order has not been processed
 * 0 = order processed, nothing ordered/recieved/invoiced
 */
export const isRecordInvalid = ({ invoicedQty, orderQty, receivedQty }) => {
  /**
   * records have the potential to be string values when fetched from the
   * sqllite db. Null values do not work well with comparisons.
   */
  const recieved = isPendingProcessing(receivedQty) ? -1 : Number(receivedQty);
  const ordered = isPendingProcessing(orderQty) ? -1 : Number(orderQty);
  const invoiced = isPendingProcessing(invoicedQty) ? -1 : Number(invoicedQty);

  // order has not been processed for recieved
  if (recieved === -1 && invoiced === ordered) {
    return false;
  }

  // order has not been processed for invoicing or recieved
  if (recieved === -1 && invoiced === -1) {
    return false;
  }

  if (invoiced < ordered || recieved < ordered) {
    return true;
  }

  return false;
};
