import { Injectable } from '@angular/core';
import { DvoHttpClientService } from 'src/app/services/dvo-http/dvo-http-client.service';
import { Observable, throwError } from 'rxjs';
import { transformScanCode, BarcodeSymbology } from '@wfmes/mobile';

import { AppSettingsService } from 'src/app/services/app-settings/app-settings.service';
import { AppDBService } from 'src/app/services/app-db/app-db.service';
import { DvoEntityService } from 'src/app/services/dvo-entity/dvo-entity.service';
import { Item, CacheDBItemRaw, CacheDBItem, WebItem } from 'src/app/app.interfaces';
import { formatItemInfoForDisplay } from './utils/formatItem';
import { itemSQLTransform, movementSQLTransform, poHistorySQLTransform } from './utils/transformSQL';

import { selectItemByUPC, selectMovementByUPC, selectPoHistoryByUPC } from './sql';
import { ItemStatus } from 'src/app/constants';

@Injectable({
  providedIn: 'root',
})
export class ItemService {
  private shouldEnableLiveServices = false;

  constructor(
    private dvoAPI: DvoHttpClientService,
    private appSettings: AppSettingsService,
    private offlineDataStore: AppDBService,
    private dvoEntity: DvoEntityService
  ) {
    this.shouldEnableLiveServices = this.appSettings.shouldEnableLiveServices();
  }

  /**
   * @description Gets item detail by UPC
   * this method pulls data from either a cache or a web service.
   * it will also check for any current items in the temporary data store
   * and merge new data with existing to maintain edited values
   * @param upc
   */
  public getItemDetails(upc: string): Observable<Item> {
    try {
      const existingItem = this.dvoEntity.getItemByUpc(upc);

      if (this.shouldEnableLiveServices) {
        return this.getItemsOverHTTP(upc, existingItem);
      }

      return this.getItemFromCacheDB(upc, existingItem);
    } catch (ex) {
      console.error('error fetching item details');
    }
  }

  /**
   * @description Transform a given UPC to extract the item code.
   * If the barcode symbology cannot be determined then the
   * original UPC is returned. May throw error if Application
   * Identifier is not supported (ie: Batch/Lot).
   * @param {string} scanCode UPC to transform
   * @param {BarcodeSymbology} symbology Symbology of scanned code
   * @returns {string} The transformed UPC string
   */
  public getTransformedUPC(scanCode: string, symbology: BarcodeSymbology = null): string {
    // UPC values returned from backend have a length of 13, with
    // padded "0"s prepended to the UPC to reach that length.
    // We specify this option for the transform so we can accurately
    // match against duplicate UPC's already entered.
    const options = {
      padLength: 13,
    };

    return transformScanCode({ scanCode, symbology, options });
  }

  getItemsOverHTTP(upc, existingItem): Observable<Item> {
    return new Observable((observer) => {
      this.dvoAPI.request('GET', `item/${upc}`, {}).subscribe(
        (data: WebItem) => {
          if (data.status === ItemStatus.DISCONTINUED) {
            observer.error(new Error('Item Discontinued'));
            observer.unsubscribe();
            return;
          }

          const displayItem: Item = formatItemInfoForDisplay({ ...existingItem, ...data });
          observer.next(displayItem);
        },
        (err) => {
          const { status } = err;
          const obsErr = (message) => observer.error(new Error(message));

          switch (status) {
            case 404:
              obsErr('Item Not Found');
              break;
            case 502:
              obsErr('Bad Gateway');
              break;
            default:
              obsErr('Unkown Error');
          }
          observer.unsubscribe();
        },
        () => observer.complete()
      );
    });
  }

  getItemFromCacheDB(upc, existingItem): Observable<Item> {
    const itemQuery = selectItemByUPC(upc);
    const movementQuery = selectMovementByUPC(upc);
    const poHistoryQuery = selectPoHistoryByUPC(upc);

    const itemRequest = this.offlineDataStore.query(itemQuery);
    const movementRequest = this.offlineDataStore.query(movementQuery);
    const poHistoryRequest = this.offlineDataStore.query(poHistoryQuery);

    return new Observable((observer) => {
      try {
        itemRequest.subscribe((itemData) => {
          movementRequest.subscribe((mvmtData) => {
            poHistoryRequest.subscribe((poHistoryData) => {
              if (!itemData.length) {
                observer.error(new Error('Item Not Found'));
                observer.unsubscribe();
                return;
              }

              const sqlItem: CacheDBItemRaw = itemData[0];

              if (sqlItem.status === ItemStatus.DISCONTINUED) {
                observer.error(new Error('Item Discontinued'));
                observer.unsubscribe();
                return;
              }

              const item: CacheDBItem = {
                ...itemSQLTransform(sqlItem),
                itemMovements: movementSQLTransform(mvmtData),
                purchaseOrderHistory: poHistorySQLTransform(poHistoryData),
              };

              const formattedItem: Item = formatItemInfoForDisplay({
                ...existingItem,
                ...item,
              });

              observer.next(formattedItem);
              observer.complete();
            });
          });
        });
      } catch (ex) {
        observer.error(ex);
      }
    });
  }
}
