import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { toBool } from 'src/app/services/app-settings/app-settings.service';

import { AppSettingsService } from 'src/app/services/app-settings/app-settings.service';
import { LoadCacheComponent } from 'src/app/pages/home/load-cache/load-cache.component';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { PoHistoryService } from 'src/app/services/po-history/po-history.service';

import { DVO_SESSION_KEY } from 'src/app/constants';
import * as moment from 'moment';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  public shouldEnableWebServices: boolean;

  constructor(
    private appSettings: AppSettingsService,
    private modalController: ModalController,
    private alertController: AlertController,
    private storage: LocalStorageService,
    private activatedRoute: ActivatedRoute,
    private poHistory: PoHistoryService
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe((queryParamMap) => {
      this.shouldEnableWebServices = toBool(queryParamMap.get('ENABLE_LIVE_SERVICES'));
    });
  }

  async updateCacheMode() {
    this.shouldEnableWebServices = !this.shouldEnableWebServices;

    if (this.shouldEnableWebServices) {
      await this.enableWebServices();
    } else {
      await this.enableCacheDB();
    }
  }

  async enableWebServices() {
    try {
      // turn off background updates
      this.poHistory.clearPOHistoryInterval();
    } catch (ex) {
      console.log('unable to clear background process. it might not be running');
    }
    this.storage.update(DVO_SESSION_KEY, {
      cacheDB: {
        isEnabled: false,
        isError: false,
        isBackgroundUpdateActive: false,
        date: moment(),
      },
    });
    this.appSettings.disableOfflineCacheMode().then(() => {
      location.reload();
    });
  }

  async enableCacheDB() {
    const modal = await this.modalController.create({
      component: LoadCacheComponent,
    });

    modal.present();

    modal.onDidDismiss().then(async (isReady) => {
      console.log(`Cache initialization: ${isReady.data}`);
      const isCacheReady = isReady.data as boolean;

      if (!isCacheReady) {
        this.displayCacheFailure();
      }

      if (isCacheReady) {
        this.appSettings.enableOfflineCacheMode().then(async () => {
          // force reload the entire app
          // so services use the new config
          await location.reload();
        });
      }
    });
  }

  // TODO: Remove duplicated code.
  async displayCacheFailure() {
    const alert = await this.alertController.create({
      header: 'Cache Download Failed',
      message: 'We were unable to download offline data. <br></br> You may still proceed using the application in online mode.',
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            this.shouldEnableWebServices = true;
          },
        },
      ],
    });

    await alert.present();
  }
}
