import { Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-timeout-message',
  templateUrl: './timeout-message.component.html',
  styleUrls: ['./timeout-message.component.scss']
})
export class TimeoutMessageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
