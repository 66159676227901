import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import * as moment from 'moment';

import { DvoRouterService } from 'src/app/services/dvo-router/dvo-router.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { SEM_VER, DVO_SESSION_KEY, Paths, AppOptions } from 'src/app/constants';
import { SavedOrdersService } from 'src/app/services/saved-orders/saved-orders.service';
import { SavedCreditsService } from 'src/app/services/saved-credits/saved-credits-service.service';
import { DvoEntityService } from 'src/app/services/dvo-entity/dvo-entity.service';
import { LoadCacheComponent } from 'src/app/pages/home/load-cache/load-cache.component';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  isLoading = false;
  username: string;
  password: string;

  @ViewChild('loginForm', { static: true }) loginForm: FormGroupDirective;

  public inactiveLogout: boolean = false;
  public appVersion = SEM_VER;

  private isCacheReady: boolean = false;

  constructor(
    private router: DvoRouterService,
    private auth: AuthService,
    private alertController: AlertController,
    private activatedRoute: ActivatedRoute,
    private savedOrdersService: SavedOrdersService,
    private savedCreditsService: SavedCreditsService,
    private dvoEntity: DvoEntityService,
    private modalController: ModalController,
    private storage: LocalStorageService
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((queryParamCollection) => {
      if (queryParamCollection['INACTIVE'] === 'true') {
        // If the user was logged out due to inactivity then
        // we need to display the message on the screen.
        this.inactiveLogout = true;

        // This removes the query param so if the user refreshes
        // the page it won't persist the inactivity message.
        this.router.navigateByUrl([], {
          replaceUrl: true,
          relativeTo: this.activatedRoute,
          queryParams: { INACTIVE: null },
          queryParamsHandling: 'merge',
        });
      }
    });

    if (this.auth.isTokenValid()) {
      this.isLoading = true;

      this.prepareApplication({ shouldLoadCacheDB: false }).then(() => {
        this.router.navigateByUrl(`/${Paths.home}`).then(() => {
          this.isLoading = false;
        });
      });
    }
  }

  async loadCache(): Promise<any> {
    const modal = await this.modalController.create({
      component: LoadCacheComponent,
    });

    modal.present();

    return modal.onDidDismiss().then((isReady) => {
      console.log(`Cache initialization: ${isReady.data}`);
      this.isCacheReady = isReady.data as boolean;

      if (this.isCacheReady) {
        this.storage.update(DVO_SESSION_KEY, {
          cacheDB: {
            isEnabled: this.isCacheReady,
            isError: !this.isCacheReady,
            date: this.isCacheReady ? moment() : null,
          },
        });
        return Promise.resolve();
      } else {
        return Promise.reject();
      }
    });
  }

  async displayCacheFailure() {
    const alert = await this.alertController.create({
      header: 'Cache Download Failed',
      message: 'We were unable to download offline data. <br></br> You may still proceed using the application in online mode.',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            this.storage.update(DVO_SESSION_KEY, {
              cacheDB: {
                isEnabled: false,
                isError: true,
                date: moment(),
              },
            });

            // start app using web services
            // since the cache failed to setup correctly
            this.router.navigateByUrl(`/${Paths.home}`, {
              queryParamsHandling: 'merge',
              queryParams: {
                ENABLE_LIVE_SERVICES: true,
              },
            });
          },
        },
      ],
    });

    await alert.present();
  }

  prepareApplication({ shouldLoadCacheDB = true } = {}) {
    return new Promise((resolve, reject) => {
      try {
        // In the event that the app crashed before the user was able
        // to save progress, we want to check if a current entity exists
        // so we can save it.
        this.dvoEntity.saveCurrentEntity({ deleteEntityAfterSave: true });

        this.savedOrdersService.deleteOldOrders();
        this.savedCreditsService.deleteOldCredits();
      } catch (ex) {
        console.error('Error when cleaning up previous orders on launch');
        console.error(ex);
      }

      if (shouldLoadCacheDB) {
        try {
          this.loadCache().then(
            () => {
              resolve();
            },
            () => {
              reject();
            }
          );
        } catch (ex) {
          reject();
        }
      } else {
        resolve();
      }
    });
  }

  login() {
    this.isLoading = true;
    this.auth.login(this.username, this.password).then(
      () => {
        // Reset the "inactiveLogout" value so that if the user
        // manually logs out they will not see the inactivity message
        // shown from a previous forced logout.
        this.inactiveLogout = false;
        this.loginForm.reset();

        this.prepareApplication().then(
          () => {
            this.isLoading = false;
            this.router.navigateByUrl(`/${Paths.home}`);
          },
          () => {
            // rejected promise signals a failed
            // cache setup. start the app using
            // web services
            this.isLoading = false;
            this.displayCacheFailure();
          }
        );
      },
      () => this.displayFailedLogin()
    );
  }

  async displayFailedLogin() {
    this.isLoading = false;
    const alert = await this.alertController.create({
      header: 'Login Failed',
      message: 'Username or password invalid. Please try again',
      buttons: ['OK'],
      backdropDismiss: false,
    });

    alert.present();
  }
}
