import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-store-location',
  templateUrl: './store-location.component.html',
  styleUrls: ['./store-location.component.scss']
})
export class StoreLocationComponent implements OnInit {
  //TODO: Pull current store info from localStorage
  public currentBU: string = '';
  public currentStore: string = '';

  constructor(private user: UserService) { }

  ngOnInit() {
    this.currentBU = this.user.getBusinessUnit();
    this.currentStore = this.user.getStoreName();
  }

}
