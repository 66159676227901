import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Paths } from 'src/app/constants';
import { AppSettingsService } from 'src/app/services/app-settings/app-settings.service';
import { DvoRouterService } from 'src/app/services/dvo-router/dvo-router.service';

@Injectable({
  providedIn: 'root',
})
export class AuthRouteGuard implements CanActivate {
  private enableLiveServices = false;
  constructor(private auth: AuthService, private router: DvoRouterService, private settings: AppSettingsService) {
    this.enableLiveServices = this.settings.shouldEnableLiveServices();
  }

  canActivate(route: ActivatedRouteSnapshot): boolean | Promise<boolean> {
    if (this.auth.isTokenValid()) {
      return true;
    }
    return this.auth.confirmContinueSession();
  }
}
