import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Subject, Observable, Subscription } from 'rxjs';
import * as moment from 'moment';
import { AlertController } from '@ionic/angular';

import { DvoRouterService } from 'src/app/services/dvo-router/dvo-router.service';
import { DVO_SESSION_KEY } from 'src/app/constants';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { DvoHttpClientService } from 'src/app/services/dvo-http/dvo-http-client.service';
import { DvoEntityService } from 'src/app/services/dvo-entity/dvo-entity.service';
import { PoHistoryService } from 'src/app/services/po-history/po-history.service';
import { NavigationExtras } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';
import { LogRocketService } from 'src/app/services/logrocket/logrocket.service';

interface User {
  user: string;
  password: string;
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private APP_STORAGE_KEY = DVO_SESSION_KEY;
  private userLoggedIn = new Subject<boolean>();
  errorStatusSub: Subscription;

  constructor(
    private dvoAPI: DvoHttpClientService,
    private storage: LocalStorageService,
    public jwtHelper: JwtHelperService,
    private router: DvoRouterService,
    private alertController: AlertController,
    private dvoEntity: DvoEntityService,
    private poHistory: PoHistoryService,
    private user: UserService,
    private logRocket: LogRocketService
  ) {
    // Initialize LogRocket if user is already logged in. If already initialized, it will not be run twice
    if (this.isTokenValid()) {
      this.logRocket.init();
    }
  }

  private setSession(token: string): void {
    const tokenObject = this.jwtHelper.decodeToken(token);
    const tokenExpiration = moment.unix(tokenObject.exp).utc();

    const dvoAppToken = {
      accessToken: token,
      expiresAt: tokenExpiration,
      cacheDB: {
        date: moment(),
        isEnabled: false,
        isError: false,
      },
    };
    this.storage.saveItem(this.APP_STORAGE_KEY, dvoAppToken);
  }

  private emitUserAuthChange(): void {
    this.userLoggedIn.next(this.isTokenValid());
  }

  async login(user: string, password: string) {
    try {
      // @ts-ignore
      const { token } = await this.dvoAPI
        .request('POST', 'login', {
          body: {
            user,
            password,
          },
        })
        .toPromise();

      this.initUser(token);
    } catch (err) {
      console.error(err);
      throw new Error(err);
    }
  }

  initUser(token: string) {
    try {
      this.setSession(token);

      const authUser = this.user;

      // Initialize LogRocket. Will not be processed if already initialized.
      this.logRocket.init();
      // Identify the user in LogRocket
      this.logRocket.identify({
        id: authUser.getUserId(),
        name: authUser.getUsername(),
        dcBu: authUser.getBusinessUnit(),
        store: authUser.getStoreName(),
      });

      // default 30 min interval
      this.poHistory.setPOHistoryInterval();
      this.emitUserAuthChange();

      // Subscribe here to the error code to check for 401s and ask user to get refresh token to continue
      this.errorStatusSub = this.dvoAPI.onError.subscribe(
        (status) => {
          if (status.errorResponseCode === 401) {
            this.confirmContinueSession();
          }
        },
        (err) => {
          console.error(err);
        }
      );
    } catch (ex) {
      throw new Error(`Error initializing DVO user: ${ex.message}`);
    }
  }

  async getRefreshToken() {
    try {
      const { token } = await this.dvoAPI.request('GET', 'login/refreshtoken', {}).toPromise();

      this.setSession(token);
      this.emitUserAuthChange();
    } catch (err) {
      throw new Error(err);
    }
  }

  async logout({ inactive } = { inactive: false }) {
    // Save in-progress orders/credits
    this.dvoEntity.saveCurrentEntity({ deleteEntityAfterSave: true });

    this.storage.deleteItem(this.APP_STORAGE_KEY);
    this.emitUserAuthChange();

    // If logout was triggered due to inactivity then
    // we need to set a query param to indicate this.
    const navOptions: NavigationExtras = inactive
      ? {
          queryParams: {
            INACTIVE: true,
            ENABLE_LIVE_SERVICES: false,
          },
          queryParamsHandling: 'merge',
        }
      : {
          queryParams: {
            ENABLE_LIVE_SERVICES: false,
          },
          queryParamsHandling: 'merge',
        };

    await this.router.navigateByUrl('login', navOptions);

    try {
      // turn off background updates if the app
      // is in cache mode
      this.poHistory.clearPOHistoryInterval();
    } catch (ex) {
      console.log('unable to clear background process. it might not be running');
    }
  }

  async confirmLogout() {
    const alert = await this.alertController.create({
      header: 'Logout',
      message: 'Are you sure you want to logout?',
      buttons: [
        {
          text: 'Logout',
          handler: () => this.logout(),
        },
        {
          text: 'Cancel',
        },
      ],
      backdropDismiss: false,
    });

    await alert.present();
  }

  async confirmContinueSession(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        header: 'Session Expired',
        message: 'Request failed, do you want to stay logged in?',
        buttons: [
          {
            text: 'OK',
            handler: () => {
              resolve(true);
              this.getRefreshToken();
            },
          },
          {
            text: 'Cancel',
            handler: () => {
              resolve(false);
              this.logout();
            },
          },
        ],
        backdropDismiss: false,
      });

      await alert.present();
    });
  }

  onUserAuthChange(): Observable<boolean> {
    return this.userLoggedIn;
  }

  isTokenValid(): boolean {
    const tokenExpirationTime = this.getExpirationTime();

    if (tokenExpirationTime !== null) {
      return moment().isBefore(tokenExpirationTime);
    }

    return false;
  }

  getExpirationTime(): moment.Moment | null {
    const token = this.storage.getItem(this.APP_STORAGE_KEY);
    if (token) {
      const expiration = token.expiresAt;
      return moment(expiration);
    }

    return null;
  }
}
