import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { AlertController } from '@ionic/angular';

import { DvoRouterService } from 'src/app/services/dvo-router/dvo-router.service';
import { Paths, REASON_CODES, CREDIT, DVO_ENTITY_KEY } from 'src/app/constants';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { AuthService } from 'src/app/services/auth/auth.service';

//import { DvoEntityService } from 'src/app/services/dvo-entity/dvo-entity.service';
import {
  //Item,
  ReasonCode,
} from 'src/app/app.interfaces';

@Component({
  selector: 'app-create-credit',
  templateUrl: './create-credit.component.html',
  styleUrls: ['./create-credit.component.scss'],
})
export class CreateCreditComponent implements OnInit {
  public selectedReason: string;
  public reasons: ReasonCode[];
  //public item: Item;
  public shouldNavBackToReview: boolean;
  //public shouldModifyItem: boolean;
  private shouldActivateNavGuard: boolean;

  constructor(
    private storage: LocalStorageService,
    private router: DvoRouterService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private alertController: AlertController //private dvoEntity: DvoEntityService
  ) {}

  ngOnInit() {
    const routeState = this.activatedRoute.paramMap.pipe(map(() => window.history.state));

    this.reasons = REASON_CODES;
    //this.item = this.activatedRoute.snapshot.data.itemData;

    // In the event the item is being modified, we need to keep
    // track of this state as it continues to the next step for
    // selecting quantity.
    routeState.subscribe(({ shouldNavBackToReview = false }) => {
      this.shouldNavBackToReview = shouldNavBackToReview;
      //this.shouldModifyItem = shouldNavBackToReview || this.dvoEntity.isDuplicatedItem(this.item.upc);

      // By piggybacking off this subscription we can reset the nav guard
      // in the event the user revisits the page.
      this.shouldActivateNavGuard = true;
    });

    this.authService.onUserAuthChange().subscribe((isLoggedIn) => {
      if (!isLoggedIn) {
        this.shouldActivateNavGuard = false;
      }
    });
  }

  /**
   * @description Method used by CanDeactivateGuard to block navigation
   * changes in the event the user has not saved the item.
   */
  canDeactivate(): Promise<boolean> | boolean {
    if (this.shouldActivateNavGuard) {
      return this.confirmNavigateAway();
    }

    return true;
  }

  /**
   * @description Alert shown when user attempts to navigate away before
   * changes have been saved.
   */
  async confirmNavigateAway(): Promise<boolean> {
    return new Promise(async (resolve) => {
      const alert = await this.alertController.create({
        header: 'Unsaved Item',
        message: `Leaving the active order window without first Saving or Sending will result in the loss of your order.`,
        buttons: [
          {
            text: 'OK',
            handler: () => {
              alert.dismiss();
              resolve(true);
            },
          },
          {
            text: 'Cancel',
            handler: () => {
              alert.dismiss();
              resolve(false);
            },
          },
        ],
      });

      await alert.present();
    });
  }

  setReason(event): void {
    this.selectedReason = event.detail.name;
  }

  saveSelection() {
    // We do not need to trigger the nav guard when progressing through
    // the create/update credit steps
    this.shouldActivateNavGuard = false;

    //save the selected reason to localstorage
    this.storage.update(DVO_ENTITY_KEY, {
      reason: this.selectedReason,
    });
  }

  returnToCredit() {
    this.router.navigateByUrl(`/${Paths.reviewCredits}`);
  }

  modifyCreditOrder() {
    this.saveSelection();

    this.router.navigateByUrl(`/${Paths.reviewCredits}`);
  }

  continue() {
    this.saveSelection();

    this.router.navigateByUrl(`/${Paths.itemEntry}/${CREDIT}`);
  }
}
