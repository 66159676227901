import { Component, OnInit, Input, OnDestroy, EventEmitter, Output } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { Paths, DVO_ENTITY_KEY } from 'src/app/constants';
import { DvoRouterService } from 'src/app/services/dvo-router/dvo-router.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { Subscription } from 'rxjs';
import { DvoEntityService } from 'src/app/services/dvo-entity/dvo-entity.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() shouldDisplayCreditCart: boolean = false;
  @Input() shouldDisplayOrderCart: boolean = false;
  @Output() onReviewOrdersClicked: EventEmitter<any> = new EventEmitter();
  @Output() onReviewCreditsClicked: EventEmitter<any> = new EventEmitter();

  public totalItemsScannedNum: number = 0;
  private storageSub = new Subscription();

  constructor(
    private menu: MenuController,
    private router: DvoRouterService,
    private storage: LocalStorageService,
    private dvoEntity: DvoEntityService
  ) {}

  ngOnInit() {
    this.totalItemsScannedNum = this.dvoEntity.getTotalItemsScanned();
    this.storageSub = this.storage.watchStorage().subscribe((data: string) => {
      if (data === DVO_ENTITY_KEY || data === 'CLEAR') {
        this.totalItemsScannedNum = this.dvoEntity.getTotalItemsScanned();
      }
    });
  }

  ngOnDestroy() {
    this.storageSub.unsubscribe();
  }

  displayMenu() {
    this.menu.open();
  }

  reviewOrders() {
    this.onReviewOrdersClicked.emit();
    this.router.navigateByUrl(Paths.orderItemReview);
  }

  reviewCredits() {
    this.onReviewCreditsClicked.emit();
    this.router.navigateByUrl(Paths.reviewCredits);
  }
}
