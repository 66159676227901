import { Injectable } from '@angular/core';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { JwtHelperService } from '@auth0/angular-jwt';

interface DvoToken {
  exp: number;
  iss: string;
  aud: string;
  sub: string;
  jti: string;
  'WFM.DVO.UserId': string;
  'WFM.DVO.BusinessUnit': string;
  'WFM.DVO.StoreName': string;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private storage: LocalStorageService, private jwtUtil: JwtHelperService) {}

  private get tokenObject(): DvoToken {
    return this.jwtUtil.decodeToken(this.storage.getToken());
  }

  getUserId(): number {
    return Number(this.tokenObject['WFM.DVO.UserId']);
  }

  getUsername() {
    return this.tokenObject.sub;
  }

  getStoreName() {
    return this.tokenObject['WFM.DVO.StoreName'];
  }

  getBusinessUnit() {
    return this.tokenObject['WFM.DVO.BusinessUnit'];
  }
}
