import { Component, OnInit, OnDestroy } from '@angular/core';
import { PopoverController, MenuController, AlertController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';

import { DvoRouterService } from 'src/app/services/dvo-router/dvo-router.service';
import { Order, Subteam, Item } from 'src/app/app.interfaces';
import { OrderService } from 'src/app/services/order/order.service';
import { OrderReviewMenuComponent } from './order-review-menu/order-review-menu.component';
import { UserService } from 'src/app/services/user/user.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { SavedOrdersService } from 'src/app/services/saved-orders/saved-orders.service';
import { WarningService } from 'src/app/services/warning/warning.service';
import { DvoEntityService } from 'src/app/services/dvo-entity/dvo-entity.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Paths, ORDER, DVO_ENTITY_KEY } from 'src/app/constants';

@Component({
  selector: 'app-order-item-review',
  templateUrl: './order-item-review.component.html',
  styleUrls: ['./order-item-review.component.scss'],
})
export class OrderItemReviewComponent implements OnInit, OnDestroy {
  private order;
  public sorting = 'description';
  public totalCasesScannedNum: Number = 0;
  public totalItemsScannedNum: Number = 0;
  public subteam: Subteam;
  private storageSub = new Subscription();
  private shouldActivateNavGuard = true;
  isLoading = false;

  constructor(
    private router: DvoRouterService,
    public popoverController: PopoverController,
    private orderService: OrderService,
    private menu: MenuController,
    private alertController: AlertController,
    private user: UserService,
    private toast: ToastService,
    private savedOrders: SavedOrdersService,
    private warning: WarningService,
    private dvoOrder: DvoEntityService,
    private storage: LocalStorageService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.order = this.dvoOrder.current();
    this.subteam = this.dvoOrder.getCurrentSubTeam();

    this.totalItemsScannedNum = this.dvoOrder.getTotalItemsScanned();
    this.totalCasesScannedNum = this.dvoOrder.getTotalCasesScanned();

    this.storageSub = this.storage.watchStorage().subscribe((storageKey: string) => {
      if (storageKey === DVO_ENTITY_KEY) {
        this.order = this.dvoOrder.current();
        this.subteam = this.dvoOrder.getCurrentSubTeam();
        this.totalItemsScannedNum = this.dvoOrder.getTotalItemsScanned();
        this.totalCasesScannedNum = this.dvoOrder.getTotalCasesScanned();
      }
    });

    const routeState = this.activatedRoute.paramMap.pipe(map(() => window.history.state));

    routeState.subscribe(() => {
      // By piggybacking off this subscription we can reset the nav guard
      // upon subsequent route changes
      this.shouldActivateNavGuard = true;
    });

    this.authService.onUserAuthChange().subscribe((isLoggedIn) => {
      if (!isLoggedIn) {
        this.shouldActivateNavGuard = false;
      }
    });
  }

  ngOnDestroy() {
    this.storageSub.unsubscribe();
  }

  /**
   * @description Method used by CanDeactivateGuard to block navigation
   * changes in the event the user has not saved the item.
   */
  canDeactivate(): Promise<boolean> | boolean {
    if (this.shouldActivateNavGuard) {
      return this.confirmNavigateAway();
    }

    return true;
  }

  async confirmNavigateAway(): Promise<boolean> {
    return new Promise(async (resolve) => {
      const alert = await this.alertController.create({
        header: 'Unsaved Item',
        message: `Leaving the active order window without first Saving or Sending will result in the loss of your order`,
        buttons: [
          {
            text: 'OK',
            handler: () => {
              alert.dismiss();
              resolve(true);
            },
          },
          {
            text: 'Cancel',
            handler: () => {
              alert.dismiss();
              resolve(false);
            },
          },
        ],
      });

      await alert.present();
    });
  }

  async displayReviewOrderOptions(ev: MouseEvent) {
    const popover = await this.popoverController.create({
      component: OrderReviewMenuComponent,
      event: ev,
      translucent: true,
    });

    await popover.present();

    const { data: completedAction } = await popover.onDidDismiss();

    if (completedAction === 'saved' || completedAction === 'deleted') {
      this.shouldActivateNavGuard = false;
      this.router.navigateByUrl(Paths.home);
    }
  }

  displayMenu() {
    this.menu.open();
  }

  async confirmDelete(event: MouseEvent, item: Item) {
    event.stopPropagation();

    const alert = await this.alertController.create({
      header: 'Confirm Delete',
      message: `Are you sure you would like to delete the following order item? <br></br>  ${item.description}`,
      buttons: [
        { text: 'Cancel' },
        {
          text: 'Delete',
          handler: () => {
            this.deleteOrderItem(item);
          },
        },
      ],
      backdropDismiss: false,
    });

    alert.present();
  }

  // delete inline item
  async deleteOrderItem(item: Item) {
    const { upc, description } = item;
    const items = await this.dvoOrder.deleteItemByUPC(upc);

    if (items.length) {
      this.order.items = items;
      this.toast.presentToast(`${description} Deleted`);
      this.totalItemsScannedNum = this.dvoOrder.getTotalItemsScanned();
      this.totalCasesScannedNum = this.dvoOrder.getTotalCasesScanned();
    }
  }

  validateItems(items) {
    return items.map(({ upc, quantity, description }) => {
      if (upc != '') {
        if (typeof quantity != 'string' && quantity === 1) {
          quantity = '1';
        }

        return { upc, quantity };
      } else {
        throw `Invalid UPC found for ${description}. </br></br>Please rescan if possible or remove the item.`;
      }
    });
  }

  sendOrder() {
    try {
      const { subteam, items } = this.order;

      if (items.length > 0) {
        this.isLoading = true;
        this.shouldActivateNavGuard = false;

        const orderPayload: Order = {
          orderType: 0,
          storeNumber: subteam.storeId,
          subTeamId: subteam.subTeamId,
          departmentId: subteam.departmentId,
          userId: this.user.getUserId(),
          items: this.validateItems(items),
        };

        this.orderService.submit(orderPayload).subscribe(
          (response) => {
            this.isLoading = false;
            this.displaySuccess();
          },
          (error) => {
            this.isLoading = false;
            this.displayFailure();
          }
        );
      } else {
        // Do not send orders when no items are added
        this.warning.displayWarning({
          header: 'Items Missing',
          message: 'Empty orders cannot be sent.',
        });
      }
    } catch (ex) {
      console.log(ex);
      this.displayFailure(ex, false);
      this.isLoading = false;
    }
  }

  async displaySuccess() {
    const alert = await this.alertController.create({
      header: 'Success',
      subHeader: 'Create Order',
      message: 'Your DVO order was successfully submitted.',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.completeOrder();
          },
        },
      ],
      backdropDismiss: false,
    });

    await alert.present();
  }

  completeOrder() {
    const { id: savedOrderId } = this.order;

    if (savedOrderId) {
      this.savedOrders.deleteOrderyById(savedOrderId);
      this.dvoOrder.delete();
    }
    this.router.navigateByUrl(`${Paths.home}`);
  }

  async displayFailure(message?: string, enableSaveButton = true) {
    const btns = enableSaveButton ? ['OK', { text: 'Save', handler: () => this.saveOrder() }] : ['OK'];
    const alert = await this.alertController.create({
      header: 'Failure',
      subHeader: 'Create Order',
      message: message || 'We had trouble submitting your DVO order',
      buttons: btns,
      backdropDismiss: false,
    });

    await alert.present();
  }

  async saveOrder() {
    const order = this.dvoOrder.current();

    this.savedOrders.save(order);
    this.toast.presentToast('DVO Order Saved');
  }

  scanItem() {
    this.shouldActivateNavGuard = false;
    this.router.navigateByUrl(`/${Paths.itemEntry}/${ORDER}`, { state: { shouldActivateNavGuard: true } });
  }

  reviewItemDetails(item) {
    this.shouldActivateNavGuard = false;
    this.router.navigateByUrl(`/${Paths.orderItemDetail}/${item.upc}`, { state: { shouldNavBackToReview: true } });
  }
}
