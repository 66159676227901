import { Component, OnInit, Input, ViewChild, NgZone } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { FormGroupDirective } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { map } from 'rxjs/operators';

import { BarcodeScanner, BarcodeSymbology } from '@wfmes/mobile';
import { IBarcodeScannedEvent } from '@wfmes/mobile/dist/BarcodeScanner';

import { ToastService } from 'src/app/services/toast/toast.service';
import { DvoRouterService } from 'src/app/services/dvo-router/dvo-router.service';
import { ORDER, CREDIT, Paths } from 'src/app/constants';
import { Subteam } from 'src/app/app.interfaces';
import { DvoEntityService } from 'src/app/services/dvo-entity/dvo-entity.service';
import { ItemService } from 'src/app/services/item/item.service';
import { AuthService } from 'src/app/services/auth/auth.service';
@Component({
  selector: 'app-item-entry',
  templateUrl: './item-entry.component.html',
  styleUrls: ['./item-entry.component.scss'],
})
export class ItemEntryComponent implements OnInit {
  @Input() upcEntered: string;
  upcSymbology: BarcodeSymbology | null = null;

  @ViewChild('itemEntryForm', { static: true }) itemEntryForm: FormGroupDirective;

  shouldDisplayCredit = false;
  shouldDisplayOrder = false;
  private shouldActivateNavGuard = true;

  type: string;
  subteam: Subteam;

  constructor(
    private router: DvoRouterService,
    private activatedRoute: ActivatedRoute,
    public toast: ToastService,
    private zone: NgZone,
    private dvoEntity: DvoEntityService,
    private itemService: ItemService,
    private alertController: AlertController,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((paramMap: ParamMap) => {
      this.type = paramMap.get('type');
      this.subteam = this.dvoEntity.getCurrentSubTeam();
      this.shouldDisplayCredit = this.type === CREDIT;
      this.shouldDisplayOrder = this.type === ORDER;
    });

    this.registerBarcodeHandler();

    const routeState = this.activatedRoute.paramMap.pipe(map(() => window.history.state));

    routeState.subscribe((state) => {
      const { shouldActivateNavGuard = null } = state || {};
      // By piggybacking off this subscription we can reset the nav guard
      // upon subsequent route changes
      if (shouldActivateNavGuard !== null && !shouldActivateNavGuard) {
        this.shouldActivateNavGuard = false;
      } else {
        this.shouldActivateNavGuard = true;
      }

      this.registerBarcodeHandler();
    });

    this.authService.onUserAuthChange().subscribe((isLoggedIn) => {
      if (!isLoggedIn) {
        this.shouldActivateNavGuard = false;
      }
    });
  }

  registerBarcodeHandler() {
    BarcodeScanner.registerHandler((scan: IBarcodeScannedEvent) => {
      try {
        this.zone.run(() => {
          this.addDVOItem(scan);
        });
      } catch (ex) {
        alert(ex.message);
      }
    });
  }

  clearBarcodeHandler() {
    BarcodeScanner.registerHandler((scan: IBarcodeScannedEvent) => { });
  }

  /**
   * @description Method used by CanDeactivateGuard to block navigation
   * changes in the event the user has not saved the item.
   */
  canDeactivate(): Promise<boolean> | boolean {
    if (this.shouldActivateNavGuard) {
      return this.confirmNavigateAway();
    }

    return true;
  }

  async confirmNavigateAway(): Promise<boolean> {
    return new Promise(async (resolve) => {
      const alert = await this.alertController.create({
        header: 'Unsaved Item',
        message: `Leaving the active order window without first Saving or Sending will result in the loss of your order`,
        buttons: [
          {
            text: 'OK',
            handler: () => {
              alert.dismiss();
              this.clearBarcodeHandler();
              resolve(true);
            },
          },
          {
            text: 'Cancel',
            handler: () => {
              alert.dismiss();
              resolve(false);
            },
          },
        ],
      });

      await alert.present();
    });
  }

  onReviewOrders() {
    this.clearBarcodeHandler();
    this.shouldActivateNavGuard = false;
  }

  onReviewCredits() {
    this.clearBarcodeHandler();
    this.shouldActivateNavGuard = false;
  }

  addDVOItem(scan: IBarcodeScannedEvent) {
    this.upcEntered = scan.Data;
    this.upcSymbology = scan.Symbology as BarcodeSymbology;
    this.submitManualUpc();
  }

  async submitManualUpc() {
    try {
      this.shouldActivateNavGuard = false;
      const upc = this.upcEntered.toString();
      const symbology = this.upcSymbology;
      const transformedUPC = this.itemService.getTransformedUPC(upc, symbology);

      if (this.type === ORDER) {
        await this.router
          .navigateByUrl(`${Paths.orderItemDetail}/${transformedUPC}`, { state: { shouldActivateNavGuard: false } })
          .catch((err) => {
            console.error(err);
          });
      }
      if (this.type === CREDIT) {
        await this.router
          .navigateByUrl(`${Paths.createCreditQuantity}/${transformedUPC}`, { state: { shouldActivateNavGuard: false } })
          .catch((err) => {
            console.error(err);
          });
      }

      this.itemEntryForm.reset();
      // Reset the symbology so the next submitted code doesn't accidentally try to
      // use the previous symbology.
      this.upcSymbology = null;
    } catch (error) {
      // The UPC was not able to be transformed, possibly due to an
      // unsupported Application Identifier (Batch/Lot). Allow dvo-router
      // to display alert for "Invalid UPC".
      this.displayError('Invalid UPC');
      console.error(error);

      // Reset the barcode scanner
      this.registerBarcodeHandler();
    }
  }

  async displayError(message: string) {
    const alert = await this.alertController.create({
      header: 'Error',
      message,
      buttons: ['OK'],
    });

    await alert.present();
  }
}
