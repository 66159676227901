import { DvoHttpClientService } from 'src/app/services/dvo-http/dvo-http-client.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppSettingsService } from 'src/app/services/app-settings/app-settings.service';
import { Subteam } from 'src/app/app.interfaces';
import { AppDBService } from 'src/app/services/app-db/app-db.service';
@Injectable({
  providedIn: 'root'
})
export class StoreService {
  private shouldEnabledLiveServices: boolean;

  constructor(
    private dvoAPI: DvoHttpClientService,
    private appSettings: AppSettingsService,
    private offlineStore: AppDBService,
  ) {
    this.shouldEnabledLiveServices = this.appSettings.shouldEnableLiveServices();
  }

  public getStoreSubteams(): Observable<Subteam[]> {// Uses the store id delivered in the header with JWT
    if (this.shouldEnabledLiveServices) {
      return this.dvoAPI.request('GET', 'store/subteams', {}) as Observable<Subteam[]>;
    } else {
      const query = `SELECT Name, Region, StoreId, SubteamId, DepartmentId FROM Subteam;`
      const req = this.offlineStore.query(query);

      return new Observable((observer) => {
        req.subscribe((data) => {
          //massage data into the same object
          //we get back from the service method
          const offlineSubteams: Subteam[] = data.map((subteam) => ({
              region: subteam.region,
              storeId: Number(subteam.storeid) || 0,
              subTeamId: Number(subteam.subteamid) || 0,
              departmentId: Number(subteam.departmentid) || 0,
              name: subteam.name
            })
          )
          observer.next(offlineSubteams);
          observer.complete();
        }, (err) => {
          observer.error(err)
        })
      })
    }
  }
}
