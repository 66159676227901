import { BaseItem, CacheDBItemRaw, CacheDBMovement, CacheDBPO, Movement, PO } from 'src/app/app.interfaces';

/**
 * Helper utils to transform SQLLite data structures
 * into similar objects used throughout the application
 */
export const itemSQLTransform = (sqlItem: CacheDBItemRaw): BaseItem => ({
  cost: sqlItem.cost || 0,
  description: sqlItem.description || '',
  vendorId: sqlItem.vendorid || null,
  vendorName: sqlItem.vendorname || '',
  upc: sqlItem.upc || '',
  status: sqlItem.status,
  casePack: sqlItem.casepack || 0,
  storeRank: sqlItem.storerank || null,
  regionRank: sqlItem.regionrank || null,
  subTeamRank: sqlItem.subteamrank || null,
  topMover: Boolean(Number(sqlItem.istopmover) || 0),
});

export const movementSQLTransform = (sqlMovement: CacheDBMovement[]): Movement[] =>
  sqlMovement.map((mvmt) => ({
    upc: mvmt.upc,
    date: mvmt.date,
    averageUnits: mvmt.avgunits,
    totalUnits: mvmt.totunits,
    averageCases: mvmt.avgcases,
    totalCases: mvmt.totcases,
  }));

export const poHistorySQLTransform = (sqlPoHistory: CacheDBPO[]): PO[] =>
  sqlPoHistory.map((poHistory) => ({
    id: poHistory.id,
    upc: poHistory.upc,
    orderItemId: poHistory.ordnumid,
    orderQuantity: poHistory.ordqty,
    invoicedQuantity: poHistory.einvqty,
    receivedQuantity: poHistory.recvqty,
    orderDate: poHistory.orddate,
    version: Number(poHistory.version),
  }));
