import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Paths } from 'src/app/constants';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
  canDeactivate(
    component: CanComponentDeactivate,
    _currentRoute: ActivatedRouteSnapshot,
    _currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ) {
    if (nextState && nextState.url === `/${Paths.login}`) {
      // If the next route is going to be the login page then we should
      // just allow the navigation. In-progress entities will automatically
      // be saved during logout so we don't need to display the guard.
      return true;
    } else {
      return component.canDeactivate ? component.canDeactivate() : true;
    }
  }
}
