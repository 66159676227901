import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppDBService } from 'src/app/services/app-db/app-db.service';
import { LocalStorageService } from 'src/app/services/storage/local-storage.service';
import { DVO_SESSION_KEY } from'src/app/constants';
import * as moment from 'moment';
@Component({
  selector: 'app-load-cache',
  templateUrl: './load-cache.component.html',
  styleUrls: ['./load-cache.component.scss']
})
export class LoadCacheComponent implements OnInit {

  constructor(
    private modalController: ModalController,
    private appDB: AppDBService,
    private storage: LocalStorageService
  ) { }

  async ngOnInit() {
    const appCache = await this.appDB.initCache()

    appCache.subscribe(
      () => {
        this.storage.update(DVO_SESSION_KEY, {
          cacheDB: {
            isEnabled: true,
            date: moment(),
            isError: false,
          }
        })
        this.dismiss(true)
      },
      (err) => {
        console.error(err);
        this.storage.update(DVO_SESSION_KEY, {
          cacheDB: {
            isEnabled: false,
            date: moment(),
            isError: true,
          }
        })
        this.dismiss(false)
      }
    )
  }

  dismiss = (isCacheLoaded: boolean) => {
    this.modalController.dismiss(isCacheLoaded);
  }

}
